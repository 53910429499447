import TemperatureSlider from '../../../../reusables/TemperatureSlider';
import SettingWrapper from '../components/SettingWrapper';

type TestResultScoreKey = 'eventScoreWeight' | 'noiseScoreWeight' | 'responseScoreWeight';

const labels: Record<string, string> = {
  eventScoreWeight: 'Event Score',
  noiseScoreWeight: 'Noise Score',
  responseScoreWeight: 'Response Score',
};

const defaultValue = { eventScoreWeight: 0.7, noiseScoreWeight: 0.2, responseScoreWeight: 0.1 };

export default function TestResultScoreWeights() {
  return (
    <SettingWrapper title="Test Result Score Weights" setting="testResultScore" defaultValue={defaultValue} json>
      {(testResultScore, onUpdate) => (
        <>
          {Object.keys(testResultScore).map(key => (
            <TemperatureSlider
              key={key}
              label={labels[key]}
              valueLabelDisplay="on"
              value={testResultScore[key as TestResultScoreKey] * 100}
              onChange={(_, value) => onUpdate({ ...testResultScore, [key]: (value as number) / 100 })}
              showInput
            />
          ))}
        </>
      )}
    </SettingWrapper>
  );
}
