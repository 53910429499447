import React from 'react';

interface TemperatureComponentProps {
  children: JSX.Element[][];
  className?: string;
  onMouseDown?: React.MouseEventHandler;
  colors: string[];
}

/**
 * The temperature component is a customzied Material UI slider
 * that have different background colors up to each slider.
 * Since the slider has no option to customize rendering of the slider
 * there was a need to insert 2 new dom elements in the list that will
 */
export default React.forwardRef<HTMLDivElement, TemperatureComponentProps>(function TemperatureComponent(
  { children, className, onMouseDown, colors }: TemperatureComponentProps,
  ref,
) {
  const values = children[3]?.map(element => element.props.value) ?? [];

  values.forEach((value, i) => {
    const lastValue = i === 0 ? 0 : values[i - 1];

    children[3]?.unshift(
      <span
        style={{
          position: 'absolute',
          height: 4,
          display: 'inline-block',
          backgroundColor: colors[i],
          left: `${lastValue}%`,
          width: `${value - lastValue}%`,
        }}
      />,
    );
  });

  return (
    <div ref={ref} role="button" tabIndex={0} className={className} onMouseDown={onMouseDown}>
      {/* https://reactjs.org/docs/react-api.html#reactchildren */}
      {React.Children.toArray(children)}
    </div>
  );
});
