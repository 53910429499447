import { Stack } from '@mui/material';
import ScoringFrequency from './ScoringFrequency';
import ScheduledTaskTimeout from './ScheduledTaskTimeout';
import CustomizeColors from './CustomizeColors';

export default function Configurations() {
  return (
    <Stack spacing={2}>
      {/* Cron Scoring Frequency */}
      <ScoringFrequency />

      {/* Cron Timeout */}
      <ScheduledTaskTimeout />

      {/* Threshold coloring */}
      <CustomizeColors />
    </Stack>
  );
}
