import { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import EvalManager from '../../Curriculum/Evaluations/Manager';
import { MgrListItem } from '../../Curriculum/Evaluations/Manager/interfaces';
import { makeStyles } from '@mui/styles';
import Modal from '../../../reusables/Modal';

const useStyles = makeStyles(theme => ({
  wrapper: {
    borderRadius: 6,
    backgroundColor: '#0b0c12',
    width: '100%',
    height: '80vh',
    overflow: 'hidden',
    position: 'relative',
  },
  evalManagerWrapper: {
    height: '80vh',
  },
}));

export default function RunEvaluationButton() {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<MgrListItem>();
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles();

  return (
    <Grid item>
      <Button variant="transparent" color="orange1" onClick={() => setOpen(true)}>
        Run Evaluation
      </Button>
      <Modal
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className={classes.wrapper}>
          <Grid container flexDirection="column">
            <Grid item sx={{ padding: '20px' }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="textStyle10">Run Evaluation</Typography>
                </Grid>
                <Grid item>
                  <Button component={Typography} onClick={() => setOpen(false)} color="secondary">
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.evalManagerWrapper}>
              <EvalManager
                value={searchValue}
                onChange={setSearchValue}
                selected={selected}
                onClone={() => {}}
                onSelect={setSelected}
                isExams={false}
                height="calc(100% - 50px)"
              />
            </Grid>
          </Grid>
        </div>
      </Modal>
    </Grid>
  );
}
