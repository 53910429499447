import { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CalculationsTabs from './components/CalculationsTabs';
import Configurations from './Configurations';
import { CalculationsTab } from './interfaces';
import ReturnOnInvestment from './ReturnOnInvestment';
import Scoring from './Scoring';
import ThreatPreparedness from './ThreatPreparedness';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: '2rem',
  },
}));

export default function Calculations() {
  const [activeTab, setActiveTab] = useState<CalculationsTab>('scoring');

  const classes = useStyles();

  const Calculation = useMemo(() => {
    const components: Record<CalculationsTab, () => JSX.Element> = {
      scoring: Scoring,
      'return on investment': ReturnOnInvestment,
      'threat preparedness': ThreatPreparedness,
      configurations: Configurations,
    };

    return components[activeTab];
  }, [activeTab]);

  return (
    <Box className={classes.wrapper} data-testid="calculations-wrapper">
      <CalculationsTabs activeTab={activeTab} onChange={setActiveTab} />
      <Box className={classes.content}>
        <Calculation />
      </Box>
    </Box>
  );
}
