import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { FilterContextProps, FilterResult } from './interfaces';

const FilterContext = createContext<FilterContextProps>({
  filters: [],
  setFilters: () => {},
  sortById: false,
  setSortById: () => {},
  latest: false,
  setLatest: () => {},
  applyFilters: (items: any[]) => items,
  deleteFilter: () => {},
});

export function FilterProvider({ children }: { children: ReactNode }) {
  const [filters, setFilters] = useState<FilterResult[]>([]);
  const [sortById, setSortById] = useState(false);
  const [latest, setLatest] = useState(false);

  const applyFilters = useCallback(
    (items: any) => {
      return items.filter((item: any) => filters.every((filter: FilterResult) => filter.filter(item)));
    },
    [filters],
  );

  const deleteFilter = useCallback((name: string) => {
    setFilters(prev => prev.filter(f => f.name !== name));
  }, []);

  return (
    <FilterContext.Provider
      value={{ filters, setFilters, sortById, setSortById, latest, setLatest, applyFilters, deleteFilter }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export function useFilterContext() {
  return useContext(FilterContext);
}
