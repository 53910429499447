import { useCallback, useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { FilterTypeProps, OperationType } from '../interfaces';

export default function NumericFilter({ onChange, source }: FilterTypeProps<number>) {
  const { name, title } = source;
  const [value, setValue] = useState<string>('');
  const [op, setOp] = useState<OperationType>('equals');

  const filterFunction = useCallback(
    item => {
      if (!item || item[name] === undefined) {
        return false;
      }
      if (op === 'equals') {
        return +value === item[name];
      } else if (op === 'less') {
        return item[name] < +value;
      } else {
        return item[name] > +value;
      }
    },
    [value, op, name],
  );

  useEffect(() => {
    onChange(filterFunction, +value, name, `${title} ${op} ${op === 'equals' ? '' : 'than'} ${value}`, op);
  }, [filterFunction, onChange, value, op, name, title]);

  return (
    <div>
      <RadioGroup
        defaultValue="equals"
        name="numeric-filter"
        value={op}
        onChange={v => setOp(v.target.value as OperationType)}
      >
        <FormControlLabel value="equals" control={<Radio />} label="equals" />
        <FormControlLabel value="less" control={<Radio />} label="less than" />
        <FormControlLabel value="more" control={<Radio />} label="more than" />
      </RadioGroup>
      <TextField
        fullWidth
        variant="standard"
        InputProps={{ style: { borderRadius: 3, border: 'solid 1px #61788e' } }}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </div>
  );
}
