import LiveFire from '../../../images/nav/SightGain LiveFire Training Logo.svg';
import Achievements from '../../../pages/Curriculum/Achievements';
import Activity from '../../../pages/Curriculum/Activity';
import Analysts from '../../../pages/Curriculum/Analysts';
import AnalystsEmail from '../../../pages/Curriculum/AnalystsEmail';
import Calendar from '../../../pages/Curriculum/Calendar';
import { EvaluationReview, Evaluations } from '../../../pages/Curriculum/Evaluations';
import {
  GuidedTraining,
  Learning,
  LivefireTraining,
  Selected,
  SelectedCourse,
} from '../../../pages/Curriculum/Learning';
import Overview from '../../../pages/Curriculum/Overview';
import { Role, SIP } from '../../../services/interfaces';
import { SRPModule } from '../interfaces';

const curriculumModule: SRPModule = {
  name: 'curriculum',
  description: 'Get your analysts in top condition so stopping breaches becomes second nature.',
  img: LiveFire,
  pages: [
    {
      name: 'Overview',
      icon: 'columns',
      to: '/curriculum/overview',
      exclude: [SIP.Picus],
      routes: [
        {
          path: '/curriculum/overview',
          key: 'curriculum-overview',
          roles: [Role.ADMIN, Role.INSTRUCTOR, Role.ANALYST],
          component: Overview,
        },
      ],
    },
    {
      name: 'Calendar',
      icon: 'calendar-alt',
      to: '/curriculum/calendar',
      exclude: [SIP.Picus],
      routes: [
        {
          path: '/curriculum/calendar/:id?',
          key: 'curriculum-calendar',
          roles: [Role.ADMIN, Role.INSTRUCTOR, Role.ANALYST],
          component: Calendar,
        },
      ],
    },
    {
      name: 'Analysts',
      icon: 'user-circle',
      to: '/curriculum/analysts',
      exclude: [SIP.Picus],
      routes: [
        {
          path: '/curriculum/analysts/:id?',
          key: 'curriculum-analysts',
          roles: [Role.ADMIN, Role.INSTRUCTOR],
          component: Analysts,
        },
        {
          path: '/curriculum/analysts/email/:email',
          key: 'curriculum-analysts-email',
          roles: [Role.ADMIN, Role.INSTRUCTOR],
          component: AnalystsEmail,
        },
      ],
    },
    {
      name: 'Learning',
      icon: 'th-list',
      to: '/curriculum/learning',
      exclude: [SIP.Picus],
      routes: [
        {
          path: '/curriculum/learning',
          key: 'curriculumn-learning',
          roles: [Role.ADMIN, Role.ANALYST],
          component: Learning,
        },
        {
          path: '/curriculum/learning/:curriculumId/course/:courseId/training/:classId',
          key: 'curriculum-analyst-class',
          roles: [Role.ADMIN, Role.ANALYST],
          component: GuidedTraining,
        },
        {
          path: '/curriculum/learning/:curriculumId/course/:courseId',
          key: 'curriculum-course',
          roles: [Role.ADMIN, Role.ANALYST],
          component: SelectedCourse,
        },
        {
          path: '/curriculum/learning/livefire/:id',
          key: 'curriculum-livefire',
          roles: [Role.ADMIN, Role.ANALYST],
          component: LivefireTraining,
        },
        {
          path: '/curriculum/learning/:id?',
          key: 'curriculum-learning-id',
          roles: [Role.ADMIN, Role.ANALYST],
          component: Selected,
        },
      ],
    },
    {
      name: 'Achievements',
      icon: 'medal',
      to: '/curriculum/achievements',
      exclude: [SIP.Picus],
      routes: [
        {
          path: '/curriculum/achievements',
          key: 'curriculum-achievements',
          roles: [Role.ADMIN, Role.ANALYST],
          component: Achievements,
        },
      ],
    },
    {
      name: 'Activity',
      icon: 'signal-alt',
      to: '/curriculum/activity',
      exclude: [SIP.Picus],
      routes: [
        {
          path: '/curriculum/activity/:tab?',
          key: 'curriculum-activity',
          roles: [Role.ADMIN, Role.ANALYST],
          component: Activity,
        },
      ],
    },
    {
      name: 'Evaluations',
      icon: 'bolt',
      to: '/curriculum/evaluations',
      routes: [
        {
          path: '/curriculum/evaluations/:type?',
          key: 'curriculum-evaluations',
          roles: [Role.ADMIN, Role.INSTRUCTOR, Role.FRAMEWORK_ANALYST],
          component: Evaluations,
        },
        {
          path: '/curriculum/evaluations/:type/:id',
          key: 'curriculum-evaluations-id',
          roles: [Role.ADMIN, Role.INSTRUCTOR, Role.FRAMEWORK_ANALYST],
          component: EvaluationReview,
        },
      ],
    },
  ],
};

export default curriculumModule;
