import { useEffect } from 'react';
import TextField from '../../../reusables/TextField';
import URLField from '../../../reusables/URLField';
import { SystemUser } from '../../../services/interfaces';
import Row from '../components/Row';
import { SIEMUpdateArgs } from '../interfaces';
import CredentionalsForm from '../components/CredentionalsForm';

export default function SplunkForm({ siemUser, dataIndexes, alertIndexes, update, setTestEnabled }: SplunkFormProps) {
  const { systemUser, query, protocol = 'https', host, port, password } = siemUser;

  // eslint-disable-next-line no-useless-escape
  const validHost = (h: string) =>
    /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])(\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))*$/.test(
      h,
    );
  const validPort =
    (p?: number) =>
    (p: string | number = '') =>
      /^\d{1,5}$/.test(`${p ?? ''}`);

  // Allow Test Connection when all fields are supplied
  useEffect(() => {
    setTestEnabled(!!(validHost(host as string) && validPort(port) && password));
  });

  return (
    <>
      <Row>
        <URLField protocol={protocol} host={host} port={port} onChange={update} />
      </Row>
      <Row column>
        <CredentionalsForm
          usernameValue={systemUser}
          passwordValue={password}
          tokenValue={password}
          onPasswordChange={e => update({ password: e.target.value })}
          onUsernameChange={e => update({ systemUser: e.target.value })}
          onTokenChange={e => update({ password: e.target.value })}
          usernameLabel="Username"
          usernamePlaceholder="leave blank when using a Splunk Token"
          passwordLabel="Password or Splunk Token"
          tokenLabel="Splunk Token"
        />
      </Row>
      <Row>
        <TextField onChange={e => update({ query: e.target.value })} value={query} label="Test Connection Query" />
      </Row>
      <Row>
        <TextField onChange={e => update({ dataIndexes: e.target.value })} value={dataIndexes} label="Data Indexes" />
        <TextField
          onChange={e => update({ alertIndexes: e.target.value })}
          value={alertIndexes}
          label="Alert Indexes"
        />
      </Row>
    </>
  );
}

interface SplunkFormProps {
  siemUser: SystemUser;
  dataIndexes: string;
  alertIndexes: string;
  update: (v: SIEMUpdateArgs) => void;
  setTestEnabled: (v: boolean) => void;
}
