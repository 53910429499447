import { useCallback, useEffect, useRef, useState } from 'react';
import appStore from '../../../../../AppStore';
import useSocketListener from '../../../../reusables/useSocketListener';
import { jobsService } from '../../../../services';
import { JobsFilter, JobsResult, PaginationInput } from '../../../../services/interfaces';
import { toRow } from './utilities';

interface UseJobsWatcherProps {
  fields: string;
  filter?: JobsFilter;
  pagination: PaginationInput;
}

export default function useJobsWatcher({ fields, filter, pagination }: UseJobsWatcherProps) {
  // a loading indicator for use in the data grid
  const [loading, setLoading] = useState(false);
  // make sure the parent component is still mounted
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // the callback function that will be used to fetch the data
  const callback = useCallback(async () => {
    setLoading(true);
    try {
      // fetch the jobs
      const results = await jobsService.listJobs(filter, pagination, fields, 'no-cache');
      // stop the loading indicator
      if (isMountedRef.current) {
        setLoading(false);
      }
      return results;
    } catch (err) {
      // stop the loading indicator
      if (isMountedRef.current) {
        setLoading(false);
      }
      // log the error
      appStore.error(err);
      // return an empty object
      return { jobs: [], count: 0 };
    }
  }, [filter, fields, pagination]);

  // use the socket listener to listen for updates to the jobs
  const result = useSocketListener<JobsResult>('jobs', 'update', callback);

  // destructure the results
  const { jobs = [], count = 0 } = result || {};

  // return the jobs, count, and loading indicator
  return { jobs: jobs.flatMap(toRow), count, loading };
}
