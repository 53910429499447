import { AppBar, Tab, Tabs } from '@mui/material';
import { CalculationsTab } from '../interfaces';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  bar: {
    display: 'flex',
    backgroundColor: theme.palette.darkBlue1.main,
  },
}));

const tabs: CalculationsTab[] = ['scoring', 'return on investment', 'threat preparedness', 'configurations'];

interface CalculationsTabsProps {
  activeTab: CalculationsTab;
  onChange: (value: CalculationsTab) => void;
}

export default function CalculationsTabs({ activeTab, onChange }: CalculationsTabsProps) {
  const classes = useStyles();

  return (
    <AppBar className={classes.bar} position="static" color="default">
      <Tabs
        value={tabs.indexOf(activeTab)}
        indicatorColor="secondary"
        textColor="primary"
        onChange={(_, i) => onChange(tabs[i] as CalculationsTab)}
      >
        {tabs.map((tab, i) => (
          <Tab id={`tab-${i}`} key={tab} label={tab} />
        ))}
      </Tabs>
    </AppBar>
  );
}
