import { Stack } from '@mui/material';
import ThreatPreparednessThreshold from './ThreatPreparednessThreshold';
import ThreatPreparednessSetting from './ThreatPreparednessSetting';

export default function ThreatPreparedness() {
  return (
    <Stack spacing={2}>
      {/* Threat Preparedness Threshold */}
      <ThreatPreparednessThreshold />

      {/* Threat Preparedness Setting */}
      <ThreatPreparednessSetting />
    </Stack>
  );
}
