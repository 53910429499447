export const typeCurriculumDetails = `
{
  id
  title
  description
  descriptionShort
  metadata {
    title
    table {
      entryId
      link
      description
    }
  }
  progress
  status
  difficulty
  durationDays
  expirationDays
  courses {
    id
    title
    progress
    status
    difficulty
    durationMinutes
  }
}
`;

export const typeTrainingGroupLivefireContents = `
{
  id
  title
  description
  livefireId
  testIds
}
`;

export const typeCourseDetails = `
{
  id
  title
  description
  progress
  difficulty
  classes {
    id
    title
    progress
    status
  }
}
`;

export const typeEventTrainingGroupDetails = `
{
  id
  title
  type
  description
  progress
  difficulty
  classes {
    id
    title
    difficulty
    progress
    status
  }
  courses {
    id
    title
    difficulty
    progress
    status
  }
}
`;

export const typeCourseName = `
{
  title
}
`;

export const typeTrainingGroupContentsGuidedTraining = `
{
  title
  progressPercent
  guidedTraining {
    title
    lessonIds
    quizId
    completed
  }
}
`;

export const typeTrainingGroupContentsSummary = `
{
  title
  description
  guidedTraining {
    completed
  }
  livefire {
    id
    completed
    name
    description
    status
  }
}
`;

export const typeGroupedContentDetails = `
{
  answers {
    title
    description
    answer
    correctAnswer
    correct
  }
  quiz {
    title
    description
    options
  }
  lessons {
    title
    content {
      component
      props
    }
  }
  completed
}
`;

export const typeCalendarEvents = `
{
  id
  owner
  attendee
  day
  time
  description
  trainingGroup {
    id
    type
    title
    description
    difficulty
  }
  status
}
`;

export const typeAchievement = `
{
  name
  tags
  requirements
}
`;

export const typeAchievementProgress = `
{
  startDate
  completionDate
  completedPercent
  guidance
  achievement {
    name
    tags
  }
}
`;

export const typeStudentActivity = `
{
  incomplete {
    id
    title
    difficulty
    progress
    status
  }
  completed {
    id
    title
    difficulty
    status
  }
}
`;

export const typeStudentActivityContent = `
{
  completed {
    id
    type
    livefireEvaluation {
      id
      evaluationId
    }
  }
  incomplete {
    id
    type
    livefireEvaluation {
      id
      evaluationId
    }
  }
}
`;

export const typeStudentActivityTime = `
{
  endTimestamp
  durationSeconds
}
`;

export const typeStudentActivityGroup = `
{
  title
  difficulty
  itemCount
  retakeCount
  nrOfAchievementsEarned
  totalTimeSpentMinutes
}
`;

export const typeStudent = `
{
  id
  name
  email
  curriculums
  courses
  achievements
  calendarEvents {
    attendee
    day
    trainingGroup {
      type
      title
      progress
    }
  }
}
`;

export const typeRecommendedCourses = `
{
  quickRefresher {
    id
    title
    difficulty
    status
    progress
  }
  challenge {
    id
    title
    difficulty
    status
    progress
  }
  quickRefresherParent {
    id
    title
  }
  challengeParent {
    id
    title
  }
}
`;

export const typeStudentWeeklyProgress = `
{
  week
  pass
  fail
}
`;

export const typeCompletedProgressByType = `
{
  group {
    id
    description
    title
  }
  completed
  total
  updatedAt
}
`;
