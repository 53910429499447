import { round } from '@sightgain/core/calculations';
import TextField from '../../../../reusables/TextField';
import SettingWrapper from '../components/SettingWrapper';

export default function ScheduledTaskTimeout() {
  return (
    <SettingWrapper title="Scheduled Task Timeout" setting="cronTimeout" defaultValue="7200">
      {(value, onUpdate) => (
        <TextField
          label="Scheduled Task Timeout in minutes"
          value={`${round(+value / 60, 0)}`}
          type="number"
          inputProps={{
            min: 120,
            max: 1440,
          }}
          onChange={e => onUpdate(`${round(+e.target.value * 60, 0)}`)}
        />
      )}
    </SettingWrapper>
  );
}
