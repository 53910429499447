import TemperatureSlider from '../../../../reusables/TemperatureSlider';
import SettingWrapper from '../components/SettingWrapper';

export default function ThreatPreparednessThreshold() {
  return (
    <SettingWrapper
      title="Threat Preparedness Threshold"
      setting="threatPreparednessThresholds"
      defaultValue={[20, 60]}
      json
    >
      {(threatPreparednessThresholds, onUpdate) => (
        <TemperatureSlider
          label="Threat Preparedness Levels"
          valueLabelDisplay="on"
          value={threatPreparednessThresholds}
          onChange={(_, value) => onUpdate(value as number[])}
          reversed
        />
      )}
    </SettingWrapper>
  );
}
