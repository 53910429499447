import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ColorPicker from '../../../../reusables/ColorPicker';
import { defaultColors } from '../interfaces';
import SettingWrapper from '../components/SettingWrapper';

const useStyles = makeStyles({
  colorPicker: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
});

export default function CustomizeColors() {
  const classes = useStyles();

  return (
    <SettingWrapper title="Customize Colors" setting="thresholdColors" defaultValue={defaultColors} json>
      {(thresholdColors, onUpdate) => (
        <div className={classes.colorPicker}>
          <div>
            <Typography variant="body1">Low</Typography>
            <ColorPicker
              color={thresholdColors.red}
              defaultColor={defaultColors.red}
              onChange={red => onUpdate({ ...thresholdColors, red })}
            />
          </div>
          <div>
            <Typography variant="body1">Medium Low</Typography>
            <ColorPicker
              color={thresholdColors.orange}
              defaultColor={defaultColors.orange}
              onChange={orange => onUpdate({ ...thresholdColors, orange })}
            />
          </div>
          <div>
            <Typography variant="body1">Medium High</Typography>
            <ColorPicker
              color={thresholdColors.yellow}
              defaultColor={defaultColors.yellow}
              onChange={yellow => onUpdate({ ...thresholdColors, yellow })}
            />
          </div>
          <div>
            <Typography variant="body1">High</Typography>
            <ColorPicker
              color={thresholdColors.green}
              defaultColor={defaultColors.green}
              onChange={green => onUpdate({ ...thresholdColors, green })}
            />
          </div>
        </div>
      )}
    </SettingWrapper>
  );
}
