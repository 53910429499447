import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Assessor } from '../../../../../services/interfaces';
import TileAssessor from './TileAssessor';

const useStyles = makeStyles(() => ({
  cards: {
    marginTop: 24,
    gap: 12,
    height: 'calc(100vh - 320px)',
    overflowY: 'scroll',
  },
  empty: {
    marginTop: 150,
    textAlign: 'center',
  },
}));

interface InputProps {
  data: Assessor[];
  isSubItem?: boolean;
}

const SUB_ITEM_MESSAGE = 'Assessor scoring cannot be added to a sub-items.';
const EMPTY_MESSAGE = 'Please select the "Add Input Data" button to add your assessment';

export default function Input({ data, isSubItem }: InputProps) {
  const classes = useStyles();

  if (isSubItem || !data.length) {
    return (
      <div className={classes.cards}>
        <Typography variant="h3" className={classes.empty}>
          {isSubItem ? SUB_ITEM_MESSAGE : EMPTY_MESSAGE}
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.cards}>
      {data.map(item => {
        if ('score' in item) {
          return <TileAssessor assessor={item} key={item.id} />;
        }

        return null;
      })}
    </div>
  );
}
