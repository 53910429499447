import { ComponentType, useEffect, useMemo, useState } from 'react';
import { CredentionalsPromptProps, CredentionalsTab, CredentionalsVariant } from './interfaces';
import CredentionalsTabs from './CredentionalsTabs';
import { APITokenSubForm, UsernameAndPasswordSubForm } from './subForms';
import Row from '../Row';

interface CredentionalsFormProps extends CredentionalsPromptProps {
  variant?: CredentionalsVariant;
}

export default function CredentionalsForm({ variant = 'both', ...props }: CredentionalsFormProps) {
  const [activeTab, setActiveTab] = useState<CredentionalsTab>('username/password');

  useEffect(() => {
    const isApiTokenForm = variant === 'token';

    setActiveTab(isApiTokenForm ? 'api token' : 'username/password');
  }, [variant]);

  const SubForm = useMemo(() => {
    const components: Record<CredentionalsTab, ComponentType<CredentionalsPromptProps>> = {
      'username/password': UsernameAndPasswordSubForm,
      'api token': APITokenSubForm,
    };

    return components[activeTab];
  }, [activeTab]);

  return (
    <>
      <CredentionalsTabs activeTab={activeTab} variant={variant} onChange={setActiveTab} />
      <Row content>
        <SubForm {...props} />
      </Row>
    </>
  );
}
