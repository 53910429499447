import { ChangeEvent } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFighterJet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { JobActor } from '../../../../../../../services/interfaces';
import { ActorComponentProps } from '../../../interfaces';

const useStyles = makeStyles(theme => ({
  attackerSelect: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'top',
  },
  jet: {
    height: 25,
    width: 25,
    marginTop: 5,
  },
}));

export default function AttackerSelect({ nodes, selectChange, actorSetting }: ActorComponentProps) {
  const classes = useStyles();

  const handleChange = (e: ChangeEvent<unknown>, node: JobActor) => {
    selectChange({ value: node.id, name: 'attacker' });
  };

  const value = nodes.find(node => node.id === actorSetting);

  return (
    <div className={classes.attackerSelect}>
      <FontAwesomeIcon icon={faFighterJet as IconDefinition} className={classes.jet} />
      <FormControl fullWidth>
        <Autocomplete
          options={nodes}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label="Attacker Node" />}
          onChange={handleChange}
          value={value}
          size="small"
          disableClearable
        />
      </FormControl>
    </div>
  );
}
