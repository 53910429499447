import TemperatureSlider from '../../../../reusables/TemperatureSlider';
import SettingWrapper from '../components/SettingWrapper';

type LikelihoodKey = 'technologyWeight' | 'noResponseWeight' | 'threatScoreWeight';

const labels: Record<string, string> = {
  technologyWeight: 'Technology',
  noResponseWeight: 'No Response',
  threatScoreWeight: 'Threat Score',
};

const defaultValue = { technologyWeight: 0.7, noResponseWeight: 0.2, threatScoreWeight: 0.1 };

export default function LikelihoodWeight() {
  return (
    <SettingWrapper title="Likelihood Weight" setting="likelihood" defaultValue={defaultValue} json>
      {(likelihood, onUpdate) => (
        <>
          {Object.keys(likelihood).map(key => (
            <TemperatureSlider
              key={key}
              label={labels[key]}
              valueLabelDisplay="on"
              value={likelihood[key as LikelihoodKey] * 100}
              onChange={(_, value) => onUpdate({ ...likelihood, [key]: (value as number) / 100 })}
              showInput
            />
          ))}
        </>
      )}
    </SettingWrapper>
  );
}
