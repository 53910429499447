import { Chip, Typography } from '@mui/material';
import FilterButton from './FilterButton';
import { useFilterContext } from './FilterContext';
import { FilterResult, FilterSource } from './interfaces';

export default function Filter({ sources }: { sources: FilterSource[] }) {
  const { filters, deleteFilter } = useFilterContext();

  return (
    <div>
      <Typography variant="h4" style={{ color: '#8797AB' }}>
        Filters
      </Typography>
      <div style={{ marginTop: 8, marginLeft: 2, display: 'flex', gap: 12 }}>
        <FilterButton sources={sources} />
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 12,
            height: 50,
            overflowY: 'auto',
          }}
        >
          {filters.map((f: FilterResult) => (
            <Chip
              aria-label={`remove ${f.name} filter`}
              key={`${f.name}`}
              variant="outlined"
              style={{ borderRadius: 2, height: 40 }}
              label={<div>{f.description}</div>}
              onDelete={() => deleteFilter(f.name)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
