import { makeStyles } from '@mui/styles';
import { CredentionalsTab, CredentionalsVariant } from './interfaces';
import { AppBar, Tab, Tabs } from '@mui/material';
import { useMemo } from 'react';

const tabs: CredentionalsTab[] = ['username/password', 'api token'];

const useStyles = makeStyles(theme => ({
  bar: {
    '& .MuiTabs-flexContainer': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      backgroundColor: theme?.palette?.darkBlue1?.main || '#161926',

      '& .MuiButtonBase-root': {
        minWidth: '100%',
        maxWidth: 0,
      },
    },
  },
}));

interface CredentionalsTabsProps {
  activeTab: CredentionalsTab;
  onChange: (value: CredentionalsTab) => void;
  variant?: CredentionalsVariant;
}

export default function CredentionalsTabs({ activeTab, variant = 'both', onChange }: CredentionalsTabsProps) {
  const classes = useStyles();

  const disabledTab = useMemo((): CredentionalsTab | Extract<'both', CredentionalsVariant> => {
    const basicVarint = variant === 'basic';
    const tokenVarant = variant === 'token';

    if (basicVarint) return 'api token';

    if (tokenVarant) return 'username/password';

    return 'both';
  }, [variant]);

  return (
    <AppBar className={classes.bar} position="static" color="default" data-testid="credentials-tabs">
      <Tabs
        value={tabs.indexOf(activeTab)}
        indicatorColor="secondary"
        textColor="primary"
        onChange={(_, value) => onChange(tabs[value])}
      >
        {tabs.map((tab, i) => (
          <Tab id={`tab-${i}`} key={tab} label={tab} disabled={tab === disabledTab && variant !== 'both'} />
        ))}
      </Tabs>
    </AppBar>
  );
}
