import clsx from 'clsx';
import {
  FormControl, FormHelperText, Input, InputLabel, InputProps, Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactNode, useState } from 'react';

const useStyles = makeStyles(() => ({
  control: {
    flex: ({ fullWidth }: { fullWidth?: boolean }) => `${fullWidth ? 1 : 0} 1 619px`,
    width: '100%',
  },
  select: {
    marginTop: 20,
    width: '100%',
  },
  item: {},
  input: {
    borderRadius: 3,
    border: 'solid 1px #61788e',
    flex: 1,
  },
  label: {
    color: '#fff',
    fontWeight: 'bold',
    position: 'relative',

    '&.Mui-focused': {
      color: '#fff',
    },
  },
  helper: {},
  red: {
    color: 'red',
  },
}));

export default function TextField({
  startAdornment,
  endAdornment,
  error = '',
  label,
  children = [],
  value = '',
  required = false,
  type = 'text',
  onChange,
  fullWidth = false,
  ...props
}: TextFieldProps) {
  const classes = useStyles({ fullWidth });
  const [isDirty, setIsDirty] = useState(false);

  const id = label.toLowerCase().replace(/\s/g, '-').replace(/[^a-z0-9_-]/g, '');

  return (
    <FormControl className={classes.control}>
      <InputLabel
        htmlFor={id}
        shrink
        required={required}
        disableAnimation
        className={clsx(classes.label, isDirty && error && classes.red)}
      >
        {label}
      </InputLabel>
      {type === 'select' && (
        <Select
          {...props}
          id={id}
          required={required}
          value={value}
          onChange={e => onChange({ ...e, target: { value: e.target.value as string } })}
          className={clsx(classes.select, isDirty && error && classes.red)}
        >
          {children}
        </Select>
      )}

      {type !== 'select' && (
        <Input
          {...props}
          id={id}
          autoComplete={type === 'password' ? 'new-password' : ''}
          type={type}
          value={value}
          onChange={e => onChange(e)}
          onBlur={() => setIsDirty(isDirty || !!value)}
          className={clsx(classes.input, isDirty && error && classes.red)}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          required={required}
        />
      )}
      {isDirty && error && <FormHelperText className={clsx(classes.helper, classes.red)}>{error}</FormHelperText>}
    </FormControl>
  );
}

interface TextFieldProps extends Omit<InputProps, 'error'> {
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  error?: string | boolean;
  label: string;
  children?: ReactNode[];
  value?: string;
  onChange: (e: { target: { value: string }}) => unknown;
}
