import { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Filter, useFilterContext } from '../../../../../reusables/Filter';
import { FilterSource } from '../../../../../reusables/Filter/interfaces';
import { Recommendation } from '../../../Recommendations/interfaces';
import RecommnedationCard from '../../../Recommendations/Tabs/Card';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: 'calc(100dvh - 425px)',
    overflowY: 'scroll',
  },
  empty: {
    height: 'calc(100dvh - 500px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const typeItems = [
  { text: 'ALL', value: 'all' },
  { text: 'PEOPLE', value: 'people' },
  { text: 'PROCESS TECHNOLOGY', value: 'technology' },
  { text: 'THREAT', value: 'threat' },
];

const statusItems = [
  { text: 'OPEN', value: 'priority' },
  { text: 'RESOLVED', value: 'resolved' },
];

const sources: FilterSource[] = [
  { name: 'type', title: 'Type', filterType: 'select', menuItems: typeItems },
  { name: 'status', title: 'Status', filterType: 'select', menuItems: statusItems },
  { name: 'date', title: 'Date', filterType: 'date' },
];

interface RecommendationsProps {
  data: Recommendation[];
  onResolve?: (id: string) => void;
  onIgnore?: (id: string) => void;
}

export default function Recommendations({ data, onResolve, onIgnore }: RecommendationsProps) {
  const { applyFilters } = useFilterContext();

  const classes = useStyles();

  const filteredRecommendations = useMemo(() => applyFilters(data), [data, applyFilters]);

  const isRecommendations = !!filteredRecommendations.length;

  return (
    <Stack spacing={2} justifyContent="center">
      <Filter sources={sources} />
      {isRecommendations ? (
        <Stack spacing={2} className={classes.wrapper}>
          {filteredRecommendations.map(recommendation => (
            <RecommnedationCard
              key={`recommendation-card-${recommendation.date}`}
              recommendation={recommendation}
              selectable={false}
              withActions
              withStatus
              onResolve={onResolve}
              onIgnore={onIgnore}
            />
          ))}
        </Stack>
      ) : (
        <Typography variant="h3" className={classes.empty}>
          No Recommendations
        </Typography>
      )}
    </Stack>
  );
}
