import { useState } from 'react';
import { useAbort } from '../../../../../effects';
import TemperatureSlider from '../../../../reusables/TemperatureSlider';
import { settingsService } from '../../../../services';
import Wrapper from '../components/Wrapper';

export default function ProductBakeoff() {
  const [preventThresholds, setPreventThresholds] = useState<[number, number]>([70, 80]);
  const [detectThresholds, setDetectThresholds] = useState<[number, number]>([70, 80]);
  const [clean, setClean] = useState(true);

  useAbort(
    async () => settingsService.list(['preventThresholds', 'detectThresholds']),
    result => {
      if (!result) {
        return;
      }

      const preventSetting = result.find(setting => setting.name === 'preventThresholds');
      if (preventSetting) {
        setPreventThresholds(JSON.parse(preventSetting.value));
      }

      const detectSetting = result.find(setting => setting.name === 'detectThresholds');
      if (detectSetting) {
        setDetectThresholds(JSON.parse(detectSetting.value));
      }
    },
    [],
  );

  const handleUpdate = (name: 'preventThresholds' | 'detectThresholds', value: number[]) => {
    if (name === 'preventThresholds') {
      setPreventThresholds(value as [number, number]);
    } else {
      setDetectThresholds(value as [number, number]);
    }

    setClean(false);
  };

  const handleOnSave = async () => {
    await Promise.all([
      settingsService.save({ name: 'preventThresholds', value: JSON.stringify(preventThresholds) }),
      settingsService.save({ name: 'detectThresholds', value: JSON.stringify(detectThresholds) }),
    ]);
    setClean(true);
  };

  const handleOnReset = () => {
    setPreventThresholds([70, 80]);
    setDetectThresholds([70, 80]);
    setClean(false);
  };

  return (
    <Wrapper title="Product Bake-Off" onSave={handleOnSave} onReset={handleOnReset} clean={clean}>
      <>
        <TemperatureSlider
          label="Prevent/Block"
          valueLabelDisplay="on"
          value={preventThresholds}
          onChange={(_, value) => handleUpdate('preventThresholds', value as number[])}
        />
        <TemperatureSlider
          label="Detect/Alert"
          valueLabelDisplay="on"
          value={detectThresholds}
          onChange={(_, value) => handleUpdate('detectThresholds', value as number[])}
        />
      </>
    </Wrapper>
  );
}
