import { FetchPolicy, gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import {
  AnswerInput,
  Assessment,
  AssessmentListInput,
  FileInfo,
  Framework,
  FrameworkWithScores,
  ItemMaturityLevel,
  ObjectiveResponse,
} from './interfaces';
import ServiceBase from './ServiceBase';

const LIST_ASSESSMENTS = gql`
  query Assessments($input: AssessmentInput) {
    assessments(input: $input) {
      id
      name
      description
      startDate
      endDate
      isArchived
      createdAt
      license {
        id
        name
        expires
        issued
        type
      }
      framework {
        id
        title
        name
        createdAt
        updatedAt
        isSystem
      }
      frameworks {
        id
        name
        scores {
          defaultMaturityScore
        }
      }
    }
  }
`;

const LIST_OBJECTIVES = gql`
  query Assessment($id: String!, $input: ItemAssessorInput!, $itemId: ID!) {
    assessment(id: $id) {
      frameworkItem(id: $itemId) {
        id
        name
        description
        identifier
        maturityLevel
        scores {
          id
          name
          label
          score
          passed
          threshold
          inferredBy
          metadata
        }
      }
      assessorInput(input: $input) {
        id
        questions {
          id
          type
          question
        }
        answers {
          id
          question {
            id
          }
          value
          passed
        }
        evidence {
          id
          bucket
          fileName
          createdAt
          mimeType
        }
        notes {
          id
          note
          createdAt
        }
      }
    }
  }
`;

const LIST_ITEM_MATURITY_LEVELS = gql`
  query ItemMaturityLevels($assessmentId: String!) {
    itemMaturityLevels(assessmentId: $assessmentId) {
      id
      item {
        id
        identifier
      }
      maturityLevel
      passingValue
    }
  }
`;

class AssessmentsService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['createdAt', 'startDate', 'endDate', 'updatedAt']));
    this.addMiddleWare('after', data => {
      const payload = data.assessment || data.assessments || data.createAssessment;
      if (!payload) {
        return data;
      }

      if (Array.isArray(payload)) {
        payload.forEach((assessment: Assessment) => {
          assessment.license = toDates(assessment.license, ['expires', 'issued']);
        });
      } else {
        payload.license = toDates(payload.license, ['expires', 'issued']);
        if (payload.assessorInput) {
          payload.assessorInput.evidence = toDates(payload.assessorInput.evidence, ['createdAt']);
        }
      }

      return data;
    });
  }

  async evidence(id: string): Promise<FileInfo> {
    const query = gql`
      query Evidence($id: ID!) {
        evidence(id: $id) {
          id
          bucket
          fileName
          createdAt
          mimeType
          contents
        }
      }
    `;

    const { evidence } = await this.graphql(query, { id }, 'no-cache');
    return evidence;
  }

  async create(input: any): Promise<Assessment<Framework>> {
    const query = gql`
      mutation CreateAssessment($input: CreateAssessmentInput!) {
        createAssessment(input: $input) {
          id
          name
          description
          startDate
          endDate
          createdAt
          license {
            id
            name
            expires
          }
          frameworks {
            id
            name
          }
        }
      }
    `;
    const { createAssessment } = await this.graphql(
      query,
      {
        input: {
          name: input.name,
          license: input.license.id,
          frameworks: [input.framework.id],
        },
      },
      'cache-first',
      [{ query: LIST_ASSESSMENTS, variables: { input: { pagination: { limit: 1000 } } } }],
    );

    return createAssessment;
  }

  async find(id: string, cache: FetchPolicy = 'cache-first'): Promise<Assessment<FrameworkWithScores>> {
    const query = gql`
      query Assessment($id: String!) {
        assessment(id: $id) {
          id
          name
          description
          startDate
          endDate
          createdAt
          license {
            id
            name
            expires
            issued
            type
          }
          framework {
            id
            title
          }
          frameworks {
            id
            createdAt
            lastUpdated
            name
            title
            version
            scores {
              defaultMaturityScore
            }
            groups {
              name
              items {
                id
                name
                description
                identifier
                scores {
                  id
                  name
                  label
                  score
                  passed
                  threshold
                  inferredBy
                  subsAchieved
                  subsCount
                  questionsCount
                  answersCount
                }
              }
            }
          }
        }
      }
    `;

    const { assessment } = await this.graphql(query, { id }, cache);
    return assessment;
  }

  async update(assessment: Partial<Assessment>): Promise<Assessment> {
    const query = gql`
      mutation UpdateAssessment($input: UpdateAssessmentInput!) {
        updateAssessment(input: $input) {
          id
          name
          isArchived
        }
      }
    `;
    const { updateAssessment } = await this.graphql(query, {
      input: { id: assessment.id, name: assessment.name, isArchived: assessment.isArchived || false },
    });
    return updateAssessment;
  }

  async list(
    input?: AssessmentListInput,
    fetchPolicy: FetchPolicy = 'cache-first',
  ): Promise<Assessment<FrameworkWithScores>[]> {
    const { assessments } = await this.graphql(
      LIST_ASSESSMENTS,
      {
        input: {
          search: input?.search,
          pagination: input?.pagination,
        },
      },
      fetchPolicy,
    );
    return assessments;
  }

  async objective(assessmentId: string, itemId: string): Promise<ObjectiveResponse> {
    const { assessment } = await this.graphql(LIST_OBJECTIVES, {
      id: assessmentId,
      input: { itemId },
      itemId: itemId,
    });
    return assessment;
  }

  async addAnswerArtifact(assessmentId: string, itemId: string, answer: AnswerInput[]) {
    const query = gql`
      mutation AddAssessmentArtifact($input: AddAssessmentArtifactInput!) {
        addAssessmentArtifact(input: $input) {
          id
          answers {
            id
            question {
              id
            }
            value
            passed
          }
          questions {
            id
          }
        }
      }
    `;

    const input = {
      answer,
      itemId,
      assessmentId,
    };

    const { addAssessmentArtifact } = await this.graphql(query, { input }, 'cache-first', [
      {
        query: LIST_OBJECTIVES,
        variables: {
          id: assessmentId,
          input: { itemId },
          itemId: itemId,
        },
      },
    ]);
    return addAssessmentArtifact;
  }

  async addEvidenceArtifact(assessmentId: string, itemId: string, files: File[]) {
    const query = gql`
      mutation AddAssessmentArtifact($input: AddAssessmentArtifactInput!) {
        addAssessmentArtifact(input: $input) {
          id
          answers {
            id
          }
          questions {
            id
          }
        }
      }
    `;

    const input = {
      evidence: files,
      itemId,
      assessmentId,
    };

    const { addAssessmentArtifact } = await this.graphql(query, { input }, 'cache-first', [
      {
        query: LIST_OBJECTIVES,
        variables: {
          id: assessmentId,
          input: { itemId },
          itemId: itemId,
        },
      },
    ]);
    return addAssessmentArtifact;
  }

  async addNoteArtifact(assessmentId: string, itemId: string, note: string) {
    const query = gql`
      mutation AddAssessmentNote($input: AddAssessmentArtifactInput!) {
        addAssessmentArtifact(input: $input) {
          id
          answers {
            id
          }
          questions {
            id
          }
        }
      }
    `;

    const input = {
      note,
      itemId,
      assessmentId,
    };

    const { addAssessmentArtifact } = await this.graphql(query, { input }, 'cache-first', [
      {
        query: LIST_OBJECTIVES,
        variables: {
          id: assessmentId,
          input: { itemId },
          itemId: itemId,
        },
      },
    ]);
    return addAssessmentArtifact;
  }

  async updateObjective(assessmentId: string, frameworkId: string, itemId: string, data: any) {
    const query = gql`
      mutation UpdateAssessmentObjective($input: UpdateObjectiveInput!) {
        updateAssessmentObjective(input: $input) {
          id
        }
      }
    `;

    const input = {
      assessmentId,
      frameworkId,
      itemId,
      minThreshold: data.minThreshold,
    };

    const { updateAssessmentObjective } = await this.graphql(query, { input }, 'cache-first', [
      {
        query: LIST_OBJECTIVES,
        variables: {
          id: assessmentId,
          input: { itemId },
          itemId: itemId,
        },
      },
    ]);

    return updateAssessmentObjective;
  }

  async listItemMaturityLevels(assessmentId: string): Promise<ItemMaturityLevel[]> {
    const { itemMaturityLevels } = await this.graphql(
      LIST_ITEM_MATURITY_LEVELS,
      {
        assessmentId,
      },
      'network-only',
    );
    return itemMaturityLevels;
  }

  async refreshScoring(assessmentId: string): Promise<string> {
    const query = gql`
      mutation RefreshAssessmentScoring($input: RefreshAssessmentScoringInput!) {
        refreshAssessmentScoring(input: $input)
      }
    `;
    const { refreshAssessmentScoring } = await this.graphql(query, { input: { assessmentId } }, 'no-cache');
    return refreshAssessmentScoring;
  }

  async capabilityExport(id: string): Promise<FileInfo> {
    const query = gql`
      query CapabilityExport($id: ID!) {
        capabilityExport(id: $id) {
          id
          bucket
          fileName
          createdAt
          mimeType
          contents
        }
      }
    `;
    const { capabilityExport } = await this.graphql(query, { id }, 'network-only');
    return capabilityExport;
  }
}

const assessmentsService = new AssessmentsService();
export default assessmentsService;
