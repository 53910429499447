import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip, Grid, MenuItem, Radio, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import appStore from '../../../../../AppStore';
import { useAbort } from '../../../../../effects';
import { assessmentsService, licenseService } from '../../../../services';
import { Assessment, License } from '../../../../services/interfaces';
import { useAssessmentContext } from '../AssessmentContext';
import { AssessmentView, AssessmentViewProps } from './interfaces';

const useStyles = makeStyles(theme => ({
  licenseSection: {
    padding: '20px 40px',
    alignItems: 'center',
    background: theme.palette.darkBlue2.main,
    margin: '20px 0px 20px -20px',
    width: 'calc(100% + 40px)',
  },
  row: {
    padding: '10px',
    '& + &': {
      borderTop: `1px solid ${theme.palette.grey1.main}`,
    },
  },
  footer: {
    borderTop: `1px solid ${theme.palette.grey1.main}`,
    paddingTop: '20px',
  },
}));

interface tempLicenseType {
  id: string;
  name: string;
  status: string;
}

export default function Licenses({ onChangeView, onExit }: AssessmentViewProps) {
  const classes = useStyles();
  const [licenses, setLicenses] = useState<tempLicenseType[]>([]);
  const { editAssessment, setEditAssessment, setAssessmentId } = useAssessmentContext();
  const [licenseFilter, setLicenseFilter] = useState<string>('all');

  useAbort(
    () => {
      return licenseService.list();
    },
    licenses => {
      setLicenses(licenses.filter(l => l.type === editAssessment.framework!.name));

      if (!editAssessment.license) {
        const license = licenses
          .sort((a, b) => b.expires.getTime() - a.expires.getTime())
          .find(l => l.type === editAssessment.framework!.name && l.status === 'active');
        if (!license) {
          return;
        }

        setEditAssessment(prev => ({
          ...prev,
          license: {
            id: license.id,
            name: license.name,
            expires: new Date(),
            issued: new Date(),
            type: '',
          },
        }));
      }
    },
    [],
  );

  const handleRadioChange = (event: any) => {
    const license = licenses.find(l => l.id === event.target.value) as License;

    if (license.status === 'expired') {
      return;
    }

    setEditAssessment(prev => ({
      ...(prev as Assessment),
      license: {
        id: license.id,
        name: license.name,
        expires: new Date(),
        issued: new Date(),
        type: '',
      },
    }));
  };

  const createAssessment = async () => {
    try {
      appStore.beginLoading();
      // TODO: assessments service is inconsistent with other services
      // we should be able to select fields
      const created = await assessmentsService.create(editAssessment);
      setAssessmentId(created.id);
      setEditAssessment({});
      onExit();
    } catch (err) {
      appStore.error(err);
    } finally {
      appStore.endLoading();
    }
  };

  const getChipColor = (type: string) => {
    const lower = type.toLowerCase();
    switch (lower) {
      case 'active':
        return 'green2';
      case 'expired':
        return 'orange1';
      default:
        return 'orange1';
    }
  };

  const filteredLicenses = useMemo(() => {
    if (licenseFilter === 'all') {
      return licenses;
    }

    return licenses.filter(license => license.status.toLowerCase() === licenseFilter);
  }, [licenses, licenseFilter]);

  return (
    <Grid container flexDirection="column">
      <Grid item display="flex" justifyContent="space-between">
        <Typography component="h2" variant="textStyle9" sx={{ padding: '0px 20px' }}>
          Select License
        </Typography>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" className={classes.licenseSection}>
          <Grid item flex={2}>
            <Typography id="select-license-label" variant="textStyle4">
              License Name
            </Typography>
          </Grid>
          <Grid item flex={1}>
            <Select fullWidth value={licenseFilter} onChange={e => setLicenseFilter(e.target.value)}>
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="expired">Expired</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container flexDirection="column">
          <Grid item>
            <Grid role="radiogroup" container flexDirection="column" justifyContent="space-between">
              {filteredLicenses.length === 0 && (
                <Typography variant="textStyle9" align="center" padding={5}>
                  No Licenses Available
                </Typography>
              )}
              {filteredLicenses.map(license => {
                return (
                  <Grid item key={license.id} className={classes.row}>
                    <Grid container gap={4} justifyContent="space-between">
                      <Grid item>
                        <Grid container gap={4}>
                          <Grid item>
                            <Radio
                              inputProps={{ 'aria-labelledby': 'select-license-label', role: 'radio' }}
                              onChange={handleRadioChange}
                              name="license"
                              value={license.id}
                              disabled={license.status !== 'active'}
                              checked={editAssessment.license?.id === license.id}
                            />
                          </Grid>
                          <Grid item>
                            <Chip label={license.status.toUpperCase()} color={getChipColor(license.status)} />
                          </Grid>
                          <Grid item flex={2} display="flex" alignItems="center">
                            <Typography variant="textStyle1">{license.name}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item display="flex" justifyContent="end" className={classes.footer} gap={2}>
        <Button
          color="white"
          sx={{ border: '1px solid', borderColor: 'blue3.main' }}
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-left']} />}
          onClick={() => onChangeView(AssessmentView.NEW)}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
          onClick={() => createAssessment()}
          disabled={!editAssessment.license?.id}
        >
          Create
        </Button>
        <Button
          color="orange1"
          variant="text"
          onClick={onExit}
          sx={{
            padding: '5px 15px',
          }}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
}
