import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

const useStyles: any = makeStyles(() => ({
  statusBadge: {
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.5,
    color: '#ffffff',
    padding: '5px 10px 5px 5px',
    borderRadius: '13px',
    fontFamily: 'Lexend',
    '& svg': {
      color: '#ffffff',
      marginRight: 5,
      fontSize: 14,
    },
  },
  running: {
    backgroundColor: '#117fba',
  },
  queued: {
    backgroundColor: 'rgba(71, 146, 247, 0.28)',
  },
  waiting: {
    backgroundColor: 'rgba(71, 146, 247, 0.28)',
  },
  ended: {
    backgroundColor: '#5d6878',
  },
  completed: {
    backgroundColor: '#21bee3',
    color: '#0b0c12',
    '& > svg': {
      color: '#ffffff',
    },
  },
  resolved: {
    backgroundColor: '#21bee3',
    color: '#ffffff',
    '& > svg': {
      color: '#ffffff',
    },
  },
  errored: {
    backgroundColor: '#f85c5c',
    color: '#0b0c12',
    '& > svg': {
      color: '#ffffff',
    },
  },
  ignored: {
    backgroundColor: '#a7adb5',
    color: '#ffffff',
    '& > svg': {
      color: '#ffffff',
    },
  },
  cancelled: {
    backgroundColor: '#a7adb5',
    color: '#0b0c12',
    '& > svg': {
      color: '#ffffff',
    },
  },
  discarded: {
    backgroundColor: '#a7adb5',
    color: '#0b0c12',
    '& > svg': {
      color: '#ffffff',
    },
  },
  passed: {
    backgroundColor: '#0a0c10',
    '& > svg': {
      color: '#feba06',
    },
  },
  released: {
    backgroundColor: 'rgb(0, 255, 177)',
    color: '#0b0c12',
    '& > svg': {
      color: '#ffffff',
    },
  },
}));

function StatusBadge({ status }: StatusBadgeProps) {
  const classes = useStyles();

  const icons: { [prop: string]: [IconPrefix, IconName] } = {
    running: ['fas', 'play-circle'],
    waiting: ['fas', 'pause-circle'],
    queued: ['fas', 'pause-circle'],
    ended: ['fas', 'stop-circle'],
    ran: ['fas', 'check-circle'],
    'stoppage time': ['fas', 'play-circle'],
    completed: ['fal', 'check-circle'],
    resolved: ['fal', 'check-circle'],
    cancelled: ['fas', 'times-circle'],
    errored: ['fas', 'times-circle'],
    ignored: ['fas', 'times-circle'],
    discarded: ['fas', 'times-circle'],
    sleeping: ['fas', 'pause-circle'],
    passed: ['fas', 'star'],
    released: ['fas', 'star'],
  };

  const icon = icons[status];

  return (
    <span className={clsx(classes.statusBadge, classes[status])}>
      {icon && <FontAwesomeIcon icon={icon} />}
      {status}
    </span>
  );
}

interface StatusBadgeProps {
  status: string;
}

export default StatusBadge;
