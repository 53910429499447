import TemperatureSlider from '../../../../reusables/TemperatureSlider';
import SettingWrapper from '../components/SettingWrapper';

export default function IPExposureWeight() {
  return (
    <SettingWrapper title="IP Exposure Weight" setting="ipWeight" defaultValue={0.25}>
      {(value, onUpdate) => (
        <TemperatureSlider
          label="IP value (supplied via company profile)"
          value={value * 100}
          onChange={(event, value) => onUpdate((value as number) / 100)}
          showInput
        />
      )}
    </SettingWrapper>
  );
}
