import { InputAdornment } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from '../../../../../reusables/TextField';
import { CredentionalsPromptProps } from '../interfaces';

export default function UsernamePrompt({ ...props }: CredentionalsPromptProps) {
  const { usernameValue, onUsernameChange = () => {}, usernameError, usernameLabel = '', usernamePlaceholder } = props;

  return (
    <TextField
      value={usernameValue}
      error={usernameError}
      startAdornment={
        <InputAdornment position="start">
          <FontAwesomeIcon size="lg" icon={['fal', 'user']} />
        </InputAdornment>
      }
      label={usernameLabel}
      placeholder={usernamePlaceholder}
      onChange={onUsernameChange}
      data-testid="username-prompt"
    />
  );
}
