import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@mui/material';
import TextField from '../../../../../reusables/TextField';
import { CredentionalsPromptProps } from '../interfaces';

export default function APITokenPrompt({ ...props }: CredentionalsPromptProps) {
  const { tokenValue, onTokenChange = () => {}, tokenError, tokenLabel = '', tokenPlaceholder } = props;

  return (
    <TextField
      type="password"
      value={tokenValue}
      startAdornment={
        <InputAdornment position="start">
          <FontAwesomeIcon size="lg" icon={['fal', 'lock']} />
        </InputAdornment>
      }
      label={tokenLabel}
      onChange={onTokenChange}
      error={tokenError}
      placeholder={tokenPlaceholder}
      data-testid="api-token-prompt"
      fullWidth
    />
  );
}
