export type CalculationsTab = 'scoring' | 'return on investment' | 'threat preparedness' | 'configurations';

export const defaultColors = {
  green: 'rgba(0, 255, 177, 0.49)',
  yellow: 'rgba(255, 255, 0, 0.49)',
  orange: 'rgba(255, 165, 6, 0.49)',
  red: 'rgba(248, 92, 92, 0.49)',
  disabled: '#606060',
  none: '#203045',
};

export interface CustomValue {
  name: string;
  default: number;
  value: number;
}
