import { useMemo } from 'react';
import { Pagination, Stack, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useScoreClass } from '../../../effects';
import { Filter, useFilterContext } from '../../reusables/Filter';
import { FilterSource } from '../../reusables/Filter/interfaces';
import ScrollableTable from '../../reusables/Table';
import { TestAnalysisScore } from './interfaces';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: 'calc(100vh - 125px)',
  },
  nameRow: {
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '400px',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const sources: FilterSource[] = [
  { name: 'testId', title: 'Test ID', filterType: 'string' },
  { name: 'name', title: 'Test Name', filterType: 'string' },
  { name: 'numberOfRuns', title: '# Runs', filterType: 'numeric' },
  { name: 'prevented', title: 'Prevent/Blocked', filterType: 'numeric' },
  { name: 'detected', title: 'Detect/Alert', filterType: 'numeric' },
  { name: 'status', title: 'Errored', filterType: 'numeric' },
];

interface TestListProps {
  tests: TestAnalysisScore[];
  page: number;
  onPageChange: (value: number) => void;
}

export default function TestList({ tests = [], page = 0, onPageChange }: TestListProps) {
  const scoreClassName = useScoreClass();
  const { applyFilters } = useFilterContext();

  const classes = useStyles();

  const filteredTests = useMemo(() => applyFilters(tests), [applyFilters, tests]);
  const slicedTests = useMemo(() => {
    return filteredTests.slice(page * 100, page * 100 + 100);
  }, [filteredTests, page]);

  const count = Math.ceil(filteredTests.length / 100);

  return (
    <Stack spacing={2} className={classes.wrapper}>
      <Filter sources={sources} />
      <ScrollableTable
        headers={['Test ID', 'Name', 'Number Of Runs', 'Prevent/Blocked', 'Detect/Alert', 'Errored']}
        sortableHeaders={{
          'Test ID': {
            field: 'testId',
          },
          Name: {
            field: 'name',
          },
          'Number Of Runs': {
            field: 'numberOfRuns',
          },
          'Prevent/Blocked': {
            field: 'prevented',
          },
          'Detect/Alert': {
            field: 'detected',
          },
          Errored: {
            field: 'errored',
          },
        }}
        data={slicedTests}
        renderFns={{
          'Test ID': (row: TestAnalysisScore) => <Typography variant="body2">{row.testId}</Typography>,
          Name: (row: TestAnalysisScore) => (
            <Tooltip title={row.name}>
              <Typography variant="body1" className={classes.nameRow}>
                {row.name}
              </Typography>
            </Tooltip>
          ),
          'Number Of Runs': (row: TestAnalysisScore) => <Typography variant="body1">{row.numberOfRuns}</Typography>,
          'Prevent/Blocked': (row: TestAnalysisScore) => (
            <Typography variant="body1" className={scoreClassName(row.prevented)}>
              {`${row.prevented}%`}
            </Typography>
          ),
          'Detect/Alert': (row: TestAnalysisScore) => (
            <Typography variant="body1" className={scoreClassName(row.detected)}>
              {`${row.detected}%`}
            </Typography>
          ),
          Errored: (row: TestAnalysisScore) => (
            <Typography variant="body1" className={scoreClassName(100 - row.status)}>
              {`${row.status}%`}
            </Typography>
          ),
        }}
      />
      {count > 1 && (
        <Pagination
          color="primary"
          size="large"
          siblingCount={3}
          boundaryCount={3}
          className={classes.pagination}
          count={count}
          page={page + 1}
          onChange={(_, value) => onPageChange(value - 1)}
        />
      )}
    </Stack>
  );
}
