import { Stack } from '@mui/material';
import FrameworkScoringThresholds from './FrameworkScoringThresholds';
import FrameworkScoringWeights from './FrameworkScoringWeights';
import ProductBakeoff from './ProductBakeoff';
import RiskPosture from './RiskPosture';
import TestResultScoreWeights from './TestResultScoreWeights';

export default function Scoring() {
  return (
    <Stack spacing={2}>
      {/* Framework Scoring Threshold */}
      <FrameworkScoringThresholds />

      {/* Framework Scoring Weight */}
      <FrameworkScoringWeights />

      {/* Risk Posture */}
      <RiskPosture />

      {/* Product Bake-Off */}
      <ProductBakeoff />

      {/* Test Result Score Weight */}
      <TestResultScoreWeights />
    </Stack>
  );
}
