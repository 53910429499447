import { CredentionalsPromptProps } from '../interfaces';
import PasswordPrompt from '../prompts/PasswordPrompt';
import UsernamePrompt from '../prompts/UsernamePrompt';

export default function UsernameAndPasswordSubForm({ ...props }: CredentionalsPromptProps) {
  return (
    <>
      <UsernamePrompt {...props} />
      <PasswordPrompt {...props} />
    </>
  );
}
