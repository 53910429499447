import { format, isAfter, isBefore, parse, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { formatDateISO } from '@sightgain/core/dates';
import { FilterTypeProps } from '../interfaces';

type DateFilterOp = 'on' | 'before' | 'after';

export default function DateFilter({ onChange, source }: FilterTypeProps<Date>) {
  const { name, title } = source;
  const [op, setOp] = useState<DateFilterOp>('on');
  const [day, setDay] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [openPicker, setOpenPicker] = useState(false);

  const filterFunction = useCallback(
    item => {
      if (!item || !item[name]) {
        return false;
      }

      const parsed = parse(day, 'yyyy-MM-dd', new Date());

      const ops: Record<DateFilterOp, boolean> = {
        on: format(item[name] as Date, 'yyyy-MM-dd') === day,
        before: isBefore(item[name] as Date, parsed),
        after: isAfter(item[name] as Date, parsed),
      };

      return ops[op];
    },
    [op, day, name],
  );

  useEffect(() => {
    const parsed = parse(day, 'yyyy-MM-dd', new Date());
    onChange(filterFunction, parsed, name, `${title} ${op} ${day}`, op);
  }, [filterFunction, onChange, name, day, op, title]);

  return (
    <div>
      <RadioGroup name="date-filter" value={op} onChange={v => setOp(v.target.value as DateFilterOp)}>
        <FormControlLabel value="on" control={<Radio />} label="on" />
        <FormControlLabel value="before" control={<Radio />} label="before" />
        <FormControlLabel value="after" control={<Radio />} label="after" />
      </RadioGroup>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div>
          <DatePicker
            format="MM/dd/yyyy"
            value={parseISO(day)}
            onChange={d => {
              setDay(formatDateISO(d as Date));
              setOpenPicker(false);
            }}
            open={openPicker}
            onClose={() => setOpenPicker(false)}
            onOpen={() => setOpenPicker(true)}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
}
