import { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Modal, Typography, Button, FormControl, Select, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchBar } from '../../../../reusables/scl';
import ProductItem from './ProductItem';
import { BakeOffProduct } from '../interfaces';
import { capitalize } from '@sightgain/core/strings';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    outline: 'none',
    borderRadius: 6,
    width: '55%',
    height: '65%',
    boxShadow: '40px 40px 40px 0 rgba(0, 0, 0, 0.51)',
    border: 'solid 1px #5d6e80',
    backgroundColor: '#0b0c12',
    paddingTop: 67,
    paddingLeft: 95,
    paddingRight: 95,
    paddingBottom: 67,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
  },
  title: {
    marginBottom: 35,
  },
  select: {
    marginTop: 24,
    width: 150,
  },
  found: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxHeight: 350,
    overflowY: 'scroll',
    '& > *': {
      marginRight: 10,
    },
  },
  notShowingCount: {
    marginBottom: 10,
  },
}));

export default function AddProductDialog({
  open = false,
  products = [],
  handleClose = () => undefined,
  onSubmit,
}: AddProductDialogProps) {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [type, setType] = useState('all');

  const types = useMemo(() => {
    const uniqueTypes = [...new Set(products.map(({ type }) => type))];

    return [
      { value: 'all', label: 'All Types' },
      ...uniqueTypes.map(t => ({ value: t.toLowerCase(), label: capitalize(t) })),
    ];
  }, [products]);

  const filteredProducts = useMemo(() => {
    const query = search.toLowerCase();

    if (type === 'all' && query.length === 0) return products;

    return products.filter(product => {
      const matchesSearch = product.name.toLowerCase().includes(query);
      const matchesType = product.type === type;

      return matchesSearch || matchesType;
    });
  }, [products, search, type]);

  const isNoProducts = products.length === 0 || filteredProducts.length === 0;

  return (
    <Modal onClose={handleClose} open={open} className={classes.modal}>
      <div className={classes.wrapper}>
        <Typography variant="h2" className={classes.title}>
          Add a Product
        </Typography>
        <div className={classes.content}>
          {products.length > 0 && (
            <>
              <SearchBar placeholder="Search Products" onChange={setSearch} />
              <FormControl className={classes.select}>
                <Select value={type} onChange={e => setType(e.target.value)}>
                  {types.map(t => (
                    <MenuItem key={t.label} value={t.value}>
                      {t.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={classes.found}>
                {filteredProducts.map((item, index) => (
                  <ProductItem key={`${item.name}-${index}`} item={item} enableHover onClick={() => onSubmit(item)} />
                ))}
              </div>
            </>
          )}

          {isNoProducts && <Typography>No products found</Typography>}
        </div>
        <div>
          {filteredProducts.length < products.length && (
            <Typography className={classes.notShowingCount}>
              {products.length - filteredProducts.length} more products not displayed. Search to filter list
            </Typography>
          )}
          <Button color="primary" onClick={handleClose}>
            <FontAwesomeIcon icon={['fal', 'long-arrow-left']} />
            &nbsp;&nbsp;Back To Product Bake-Off
          </Button>
        </div>
      </div>
    </Modal>
  );
}

type AddProductDialogProps = {
  open?: boolean;
  handleClose?: () => unknown;
  products?: BakeOffProduct[];
  onSubmit: (p: BakeOffProduct) => unknown;
};
