import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, InputAdornment, InputProps } from '@mui/material';

interface SliderInputProps extends Omit<InputProps, 'onChange'> {
  onChange?: (e: Event, value: number, index: number) => void;
  value?: number | number[];
}

export default function SliderInput({ onChange, value, inputProps, ...props }: SliderInputProps) {
  if (typeof value !== 'number') {
    return null;
  }

  return (
    <Input
      type="number"
      value={value}
      onChange={e => onChange && e.target.value && onChange(e as any, +(e.target.value as string), 0)}
      inputProps={{ style: { width: 50, paddingRight: 0, ...inputProps?.style }, ...inputProps }}
      {...props}
      endAdornment={
        <InputAdornment position="end">
          <FontAwesomeIcon icon={['fal', 'percent']} />
        </InputAdornment>
      }
    />
  );
}
