import { useState } from 'react';
import { DropzoneArea } from 'mui-file-dropzone';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import appStore from '../../../../../../AppStore';
import assessmentsService from '../../../../../services/AssessmentsService';
import { useAssessmentContext } from '../../../components/AssessmentContext';
import { Objective } from '../interface';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  container: {
    border: '1px solid gray',
    backgroundColor: '#0b0c12',
    padding: '20px',
    width: '50vw',
  },
  dropzone: {
    position: 'relative',
  },
  dropzoneText: {
    width: '100%',
    position: 'absolute',
    left: 0,
    bottom: '1.5rem',
    '& p': {
      ...theme.typography.textStyle2,
      color: theme.palette.grey1.main,
      textAlign: 'center',
    },
  },
}));

export default function UploadEvidence({ onClose, objective }: { onClose?: () => void; objective: Objective }) {
  const [files, setFiles] = useState<File[]>([]);
  const classes = useStyles();
  const { assessment } = useAssessmentContext();

  async function handleAddClick() {
    if (files.length === 0) {
      return;
    }

    appStore.beginLoading();
    try {
      await assessmentsService.addEvidenceArtifact(assessment.id, objective.id, files);
      if (onClose) {
        onClose();
      }
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  }

  return (
    <div className={classes.container} data-testid="upload-evidence-form">
      <Grid width="md" container direction="column" gap={1}>
        <Grid item className={classes.dropzone}>
          <DropzoneArea
            fileObjects={files}
            onChange={f => setFiles(f)}
            acceptedFiles={[
              'image/png',
              'image/jpeg',
              'text/csv',
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]}
          />
          <Box className={classes.dropzoneText}>
            <Typography>Max file size: 3MB</Typography>
            <Typography>Supported file types: png, jpeg, csv, pdf, doc , docx, xls, xlsx</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Grid container gap={2} justifyContent="right">
            <Grid item>
              <Button color="primary" onClick={handleAddClick} variant="contained">
                Add
              </Button>
            </Grid>
            <Grid item>
              <Button color="secondary" onClick={onClose} variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
