import TemperatureSlider from '../../../../reusables/TemperatureSlider';
import SettingWrapper from '../components/SettingWrapper';

type ThreatPreparednessKey =
  | 'unknownTechniques'
  | 'preventionProbability'
  | 'detectionProbability'
  | 'alertProbability'
  | 'responseProbability';

const labels = {
  unknownTechniques: '% of unknown techniques',
  preventionProbability: 'Probability of preventing unknown techniques',
  detectionProbability: 'Probability of detecting unknown techniques',
  alertProbability: 'Probability of alerting unknown techniques',
  responseProbability: 'Probability of appropriate response',
};

const defaultValue = {
  unknownTechniques: 0.9,
  preventionProbability: 0.85,
  detectionProbability: 0.4,
  alertProbability: 0.2,
  responseProbability: 0.4,
};

export default function ThreatPreparednessSetting() {
  return (
    <SettingWrapper title="Threat Preparedness" setting="threatPreparedness" defaultValue={defaultValue} json>
      {(threatPreparedness, onUpdate) => (
        <>
          {Object.keys(threatPreparedness).map(key => (
            <TemperatureSlider
              key={key}
              label={labels[key as ThreatPreparednessKey]}
              valueLabelDisplay="on"
              value={threatPreparedness[key as ThreatPreparednessKey] * 100}
              onChange={(_, value) => onUpdate({ ...threatPreparedness, [key]: (value as number) / 100 })}
              showInput
            />
          ))}
        </>
      )}
    </SettingWrapper>
  );
}
