import { Stack } from '@mui/material';
import ROIThreshold from './ROIThreshold';
import PIIExposureWeight from './PIIExposureWeight';
import IPExposureWeight from './IPExposureWeight';
import LikelihoodWeight from './LikelihoodWeight';

export default function ReturnOnInvestment() {
  return (
    <Stack spacing={2}>
      {/* Likelihood Weight */}
      <LikelihoodWeight />

      {/* IP Exposure Weight */}
      <IPExposureWeight />

      {/* PII Exposure Weight */}
      <PIIExposureWeight />
      
      {/* ROI Threshold */}
      <ROIThreshold />
    </Stack>
  );
}
