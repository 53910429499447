import { useMemo, useState } from 'react';
import { Pagination, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFilterContext } from '../../../../../reusables/Filter';
import { FilterSource } from '../../../../../reusables/Filter/interfaces';
import { TestResult } from '../../../../../services/interfaces';
import TileDetailControls from './TileDetailControls';
import TileHistory from './TileHistory';

const useStyles = makeStyles(() => ({
  cards: {
    gap: 12,
    height: 'calc(100vh - 500px)',
    overflowY: 'scroll',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  empty: {
    height: 'calc(100vh - 500px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
}));

const menuItems = [
  { text: 'TRUE', value: true },
  { text: 'FALSE', value: false },
];

const sources: FilterSource[] = [
  { name: 'testId', title: 'Test ID', filterType: 'string' },
  { name: 'name', title: 'Test Name', filterType: 'string' },
  { name: 'prevented', title: 'Prevented', filterType: 'boolean', menuItems },
  { name: 'detected', title: 'Detected', filterType: 'boolean', menuItems },
  { name: 'alerted', title: 'Alerted', filterType: 'boolean', menuItems },
  { name: 'started', title: 'Date', filterType: 'date' },
];

export default function History({ data }: { data: Required<TestResult>[] }) {
  const classes = useStyles();
  const { filters, sortById, latest, applyFilters } = useFilterContext();

  // keep track of how far a scrolling has happened
  const [page, setPage] = useState(0);

  // filter the data based on the filters and sort it
  const filteredData = useMemo(() => {
    // determine the appropriate sort function
    const sortFn = sortById ? (a: any, b: any) => a?.testId?.localeCompare(b?.testId) : () => 0;

    // filter the data using the filters
    const filtered = applyFilters(data).filter((x: TestResult) => !x.stage.job.isExcluded);

    // if latest isn't toggled, return immediately
    if (!latest) {
      return filtered.sort(sortFn);
    }

    // find only the latest results
    const recent = filtered.reduce((acc, item) => {
      const { testId, started } = item;
      if (!acc[testId] || acc[testId].started.getTime() < started.getTime()) {
        acc[testId] = item;
      }
      return acc;
    }, {} as Record<string, Required<TestResult>>);

    // return the latest results
    return Object.values(recent).sort(sortFn);
  }, [data, filters, sortById, latest]);

  const count = Math.ceil(filteredData.length / 100);
  const isFilteredData = !!filteredData?.length;

  return (
    <Stack spacing={2} justifyContent="center">
      <TileDetailControls sources={sources} itemCount={filteredData.length} />
      {isFilteredData ? (
        <div className={classes.cards}>
          {filteredData.slice(page * 100, page * 100 + 100).map(item => (
            <TileHistory item={item} key={item.id} />
          ))}
        </div>
      ) : (
        <Typography variant="h3" className={classes.empty}>
          No tests have been executed yet. Please run an evaluation from the Available Evaluations tab.
        </Typography>
      )}
      {isFilteredData && count > 1 && (
        <Pagination
          className={classes.pagination}
          color="primary"
          size="large"
          page={page + 1}
          siblingCount={3}
          boundaryCount={3}
          count={count}
          onChange={(_, value) => setPage(value - 1)}
        />
      )}
    </Stack>
  );
}
