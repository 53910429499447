import { FormControlLabel, Switch, Typography } from '@mui/material';
import { Filter, useFilterContext } from '../../../../../reusables/Filter';
import { FilterSource } from '../../../../../reusables/Filter/interfaces';

export default function TileDetailControls({
  sources,
  itemCount,
  hideLatest = false,
}: {
  sources: FilterSource[];
  itemCount: number;
  hideLatest?: boolean;
}) {
  const { sortById, setSortById, latest, setLatest } = useFilterContext();

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Filter sources={sources} />
      <div>
        <FormControlLabel
          value="start"
          control={<Switch value={sortById} onChange={() => setSortById(!sortById)} color="primary" />}
          label="Sort by ID"
          labelPlacement="start"
        />
        {!hideLatest && (
          <FormControlLabel
            value="start"
            control={<Switch value={latest} onChange={() => setLatest(!latest)} color="primary" />}
            label="Latest"
            labelPlacement="start"
          />
        )}
        <Typography textAlign="right">{itemCount} Items</Typography>
      </div>
    </div>
  );
}
