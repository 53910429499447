import { useCallback, useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { FilterTypeProps } from '../interfaces';

type StringFilterOp = 'like' | 'exact';

export default function StringFilter({ onChange, source }: FilterTypeProps<string>) {
  const { name, title } = source;
  const [value, setValue] = useState('');
  const [op, setOp] = useState<StringFilterOp>('like');

  const filterFunction = useCallback(
    item => {
      if (!item || !item[name]) {
        return false;
      }

      // Check that a value is definitely a string
      // and not an array of strings
      const notString = typeof item[name] !== 'string';
      const itemName = notString ? item[name].join('') : item[name];

      if (op === 'exact') {
        return value.toLowerCase() === itemName.toLowerCase();
      }

      return itemName.toLowerCase().includes(value.toLowerCase());
    },
    [value, op, name],
  );

  useEffect(() => {
    onChange(filterFunction, value, name, `${title} ${op === 'exact' ? 'exactly' : ''} like ${value}`, op);
  }, [filterFunction, onChange, value, op, name, title]);

  return (
    <div>
      <RadioGroup name="string-filter" value={op} onChange={v => setOp(v.target.value as StringFilterOp)}>
        <FormControlLabel value="like" control={<Radio />} label="like match" />
        <FormControlLabel value="exact" control={<Radio />} label="exact match" />
      </RadioGroup>
      <TextField
        fullWidth
        variant="standard"
        InputProps={{ style: { borderRadius: 3, border: 'solid 1px #61788e' } }}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </div>
  );
}
