// start the websocket
import './components/services/Socket';
// start the token refresh interval
import './components/services/authRefresh';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Router, useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import appStore, { LicenseType } from './AppStore';
import { Navigation } from './components/Layout/Navigation';
import Routing from './components/Layout/routes';
import Alerts from './components/reusables/Alerts';
import EULAModal from './components/reusables/EULAModal/EULAModal';
import Loading from './components/reusables/Loading';
import PasswordResetModal from './components/reusables/PasswordResetModal';
import SightgainThemeProvider from './components/reusables/scl/provider';
import useCacheRefresh from './components/reusables/useCacheRefresh';
import { useSetting } from './components/reusables/useSetting';
import { SIP } from './components/services/interfaces';
import history from './history';
import keycloak from './keycloak';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    background: '#203045',
  },
  content: {
    flexGrow: 1,
  },
  wrapper: {
    width: '100%',
  },
  container: {
    marginTop: 40,
    marginRight: 48,
    marginBottom: 30,
    width: 'calc(100vw - 448px)',
    maxWidth: 'unset',
    height: 'calc(100vh - 70px)',
    overflow: 'hidden',
    padding: 0,
  },
  scrollContainer: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: 8,
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: 9,
      height: 9,
    },
    '*::-webkit-scrollbar-corner': {
      backgroundColor: 'unset',
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: 'unset',
    },
    '*::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.09)',
      borderRadius: 100,
    },
    '*::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(255, 255, 255, 0.18)',
    },
    '*::-webkit-scrollbar-thumb:active': {
      background: 'rgba(255, 255, 255, 0.3)',
    },
  },
  navClosed: {
    width: 'calc(100vw - 180px) !important',
  },
}));

const ThemedLayout = observer(() => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(true);
  const { keycloak } = useKeycloak();

  const licenseType = useSetting('licenseType', 'unset', v => v);
  const sip = useSetting('sip', 'unset', v => v);

  useEffect(() => {
    appStore.setLicenseType(licenseType as LicenseType);
    appStore.setSIP(sip as SIP);
  }, [licenseType, sip]);

  // if we aren't authenticated yet, we still need to route - and we don't want to render other ui elements
  if (!keycloak.authenticated || !appStore.user || /^\/signup/.test(pathname)) {
    return (
      <main>
        <Routing />
      </main>
    );
  }

  if (appStore.LicenseType === 'unset') {
    return <></>;
  }

  const isOpen = open && !appStore.isLimitedLicense;

  return (
    <>
      <Navigation open={isOpen} setOpen={setOpen} />
      <main className={classes.content}>
        <Container maxWidth="lg" className={clsx(classes.container, { [classes.navClosed]: !isOpen })}>
          <div className={classes.scrollContainer}>
            <Routing />
          </div>
        </Container>
      </main>
    </>
  );
});

const SRP = () => {
  const classes = useStyles();
  const { initialized } = useKeycloak();

  // prevent ui flashes until auth is ready!
  if (!initialized) {
    return <></>;
  }

  return (
    <SightgainThemeProvider>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Router history={history}>
            <ThemedLayout />
          </Router>
        </div>
        <EULAModal />
        <PasswordResetModal />
        <Alerts />
        <Loading />
      </div>
    </SightgainThemeProvider>
  );
};

const onKcEvent = (event: any, error: any) => {
  switch (event) {
    // handle sessions ended by keycloak
    case 'onAuthLogout':
      appStore.logout();
      break;
    default:
      break;
  }
};

const App = () => {
  useCacheRefresh();

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={onKcEvent}
      initOptions={{
        pkceMethod: 'S256',
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      }}
    >
      <SRP />
    </ReactKeycloakProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
