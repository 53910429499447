import { Card, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-bottts-sprites';
import FaIconButton from '../../../reusables/FaIconButton';
import Avatar from '../../../reusables/Avatar';
import { ProgressBar } from '../../../reusables/metrics';
import { defaultSelectedEvent } from '../utils';
import { CalendarEvent, Student } from '../../../services/interfaces';
import { IconBadge } from '../../../reusables/Badges';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: 420,
    height: 207,
    cursor: 'pointer',
  },
  cardTop: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardHeaderRight: {
    padding: '20px 20px 0px 20px',
    display: 'flex',
    flexDirection: 'row-reverse',
    '& > *': {
      marginLeft: 10,
    },
  },
  cardContent: {
    padding: '0px 20px 0px 20px',
    display: 'flex',
    flexDirection: 'column',
  },
  analystDetails: {
    display: 'flex',
    flexDirection: 'row',
  },
  analystName: {
    marginTop: 8,
    marginLeft: 10,
  },
  analystBadges: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 81,
    paddingRight: 98,
    height: 60,
  },
  badgeCount: {
    width: '80px',
    '& > span': {
      backgroundColor: 'rgba(224, 105, 77, 0.49)',
      border: '1px solid #e0694d',
      borderRadius: '3px',
      width: 28,
      height: 23,
      fontWeight: 'bold',
    },
  },
  cardFooter: {
    height: 79,
    padding: '0px 20px 20px 20px',
    borderTop: '1px solid grey',
    display: 'flex',
  },
  footerLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  footerRight: {
    marginTop: 25,
    marginLeft: 'auto',
    width: 150,
  },
  footerSubtitle: {
    color: '#a7adb5',
    marginTop: 15,
  },
  link: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  selected: {
    borderRadius: 6,
    border: 'solid 3px rgba(71, 146, 247, 0.51)',
  },
  progressBar: {
    width: 150,
  },
  curriculums: {
    color: '#ffffff',
  },
}));

const getSelectionIcon = (isSelected: boolean) => (isSelected ? 'check-square' : 'square');

export default function AnalystCard({
  analyst,
  events = [],
  isBatchSelectionActive = false,
  isSelected = false,
  onSelect,
  assignCurriculum,
}: AnalystCardProps) {
  const classes = useStyles();
  const history = useHistory();

  const avatarSvg = createAvatar(style, {
    seed: analyst.email,
    dataUri: true,
    backgroundColor: '#dddbda',
  });

  const selectedEvent = defaultSelectedEvent(events);
  const current = selectedEvent?.trainingGroup.title;

  return (
    <Card
      className={clsx(classes.wrapper, { [classes.selected]: isSelected })}
      onClick={() => {
        if (isBatchSelectionActive) {
          onSelect(analyst);
        } else {
          history.push(`/curriculum/analysts/${analyst.id}`);
        }
      }}
    >
      <div className={classes.cardTop}>
        <div className={classes.cardHeaderRight}>
          {onSelect && (
            <FaIconButton
              icon={['fal', getSelectionIcon(isSelected)]}
              onClick={e => {
                e.stopPropagation();
                onSelect(analyst);
              }}
              size="lg"
            />
          )}
        </div>
        <div className={classes.cardContent}>
          <div className={classes.analystDetails}>
            <Avatar image={avatarSvg} size="xs" />
            <Typography className={classes.analystName} variant="h5">
              {analyst.name}
            </Typography>
          </div>
          <div className={classes.analystBadges}>
            <IconBadge icon={['fal', 'medal']}>{`${analyst.achievements}`}</IconBadge>
            <IconBadge icon={['fal', 'th-list']}>{`${analyst.curriculums}`}</IconBadge>
            <IconBadge icon={['fal', 'users-class']}>{`${analyst.courses}`}</IconBadge>
          </div>
        </div>
        <div className={classes.cardFooter}>
          <div className={classes.footerLeft}>
            <div className={classes.footerSubtitle}>Current Curriculum</div>
            <Typography className={current && classes.curriculums} variant="h6">
              {current ?? 'None'}
            </Typography>
          </div>
          <div className={classes.footerRight}>
            {selectedEvent ? (
              <ProgressBar value={selectedEvent.trainingGroup.progress ?? 0} />
            ) : (
              <Link
                className={classes.link}
                onClick={e => {
                  e.stopPropagation();
                  assignCurriculum();
                }}
              >
                Assign Curriculum
              </Link>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}

type AnalystCardProps = {
  analyst: Student;
  events?: CalendarEvent[];
  isBatchSelectionActive?: boolean;
  isSelected?: boolean;
  onSelect: (s: Student) => unknown;
  assignCurriculum: () => unknown;
};
