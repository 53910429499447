import { create } from 'zustand';

interface FullScreenStoreState {
  isFullScreen: boolean;
  onFullScreen: (value: boolean) => void;
}

export const useFullScreenStore = create<FullScreenStoreState>(set => ({
  isFullScreen: false,
  onFullScreen: value => set({ isFullScreen: value }),
}));
