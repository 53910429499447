import TemperatureSlider from '../../../../reusables/TemperatureSlider';
import SettingWrapper from '../components/SettingWrapper';

export default function ROIThreshold() {
  return (
    <SettingWrapper title="ROI Threshold" setting="roiThresholds" defaultValue={[20, 60]} json>
      {(roiThresholds, onUpdate) => (
        <TemperatureSlider
          label="ROI Performance Percentage"
          valueLabelDisplay="on"
          value={roiThresholds}
          onChange={(_, value) => onUpdate(value as number[])}
        />
      )}
    </SettingWrapper>
  );
}
