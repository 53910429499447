import { round } from '@sightgain/core/calculations';

import TextField from '../../../../reusables/TextField';
import SettingWrapper from '../components/SettingWrapper';

export default function ScoringFrequency() {
  return (
    <SettingWrapper title="Scoring Frequency" defaultValue="1800" setting="cronScoringFrequency">
      {(value, onUpdate) => (
        <TextField
          label="Scoring Frequency in minutes"
          value={`${round(+value / 60, 0)}`}
          type="number"
          onChange={e => onUpdate(`${round(+e.target.value * 60, 0)}`)}
        />
      )}
    </SettingWrapper>
  );
}
