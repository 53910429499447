import { DropzoneDialog } from 'mui-file-dropzone';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import appStore from '../../../../AppStore';
import { assessorsService } from '../../../services';

interface ImportAssessorScoreProps {
  hidden?: boolean;
  onSave: () => Promise<void> | void;
}

const useStyles = makeStyles(theme => ({
  importButton: {
    backgroundColor: 'transparent',
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    fontSize: theme.typography.body2.fontSize,
  },
  importWrapper: {
    marginLeft: theme.spacing(2),
  },
}));

export default function ImportAssessorScore({ hidden, onSave }: ImportAssessorScoreProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOnSave = async (files: File[]) => {
    appStore.beginLoading();
    try {
      await assessorsService.import({
        file: files[0],
        source: 'protivity',
      });
    } catch (err) {
      appStore.error(err);
    }

    appStore.endLoading();
    setOpen(false);
    onSave();
  };

  if (hidden) {
    return null;
  }

  return (
    <>
      <div className={classes.importWrapper}>
        <Button className={classes.importButton} color="primary" variant="text" onClick={() => setOpen(true)}>
          <FontAwesomeIcon icon={['fas', 'upload']} />
          Import Assessor Score Data
        </Button>
        <DropzoneDialog
          inputProps={{ 'aria-label': 'upload file' }}
          acceptedFiles={[
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
          ]}
          cancelButtonText={'Cancel'}
          submitButtonText={'Upload'}
          open={open}
          fileObjects={[]}
          filesLimit={1}
          onClose={() => setOpen(false)}
          onSave={files => handleOnSave(files)}
          showPreviews={true}
          showFileNamesInPreview={true}
        />
      </div>
    </>
  );
}
