import { Box, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

interface ScoreBoxProps {
  title: string;
  value: number | undefined;
  infoText: string;
}

export default function ScoreBox({ title, value, infoText }: ScoreBoxProps) {
  return (
    <Box component="div" data-testid="score-box" sx={{ bgcolor: 'darkBlue4.main', padding: '10px' }}>
      <Tooltip title={infoText} style={{ float: 'right', padding: 0 }}>
        <IconButton aria-label="info" size="small">
          <InfoIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Typography style={{ fontWeight: 700, fontSize: '14px', lineHeight: '17.5px', marginTop: '5px' }}>
        {title}
      </Typography>
      <Typography
        style={{ fontWeight: 400, fontSize: '32px', lineHeight: '40px', marginTop: '5px', textWrap: 'nowrap' }}
      >
        {value === undefined ? 'No Score' : `${value}%`}
      </Typography>
    </Box>
  );
}
