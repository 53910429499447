import { useCallback, useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FilterTypeProps } from '../interfaces';

export default function BooleanFilter({ onChange, source }: FilterTypeProps<boolean>) {
  const { name, title } = source;
  const [value, setValue] = useState(false);

  const filterFunction = useCallback(
    item => {
      if (!item || item[name] === undefined) {
        return false;
      }

      return value === item[name];
    },
    [value, name],
  );

  useEffect(() => {
    onChange(filterFunction, value, name, `${title} is ${value}`);
  }, [filterFunction, onChange, value, name, title]);

  return (
    <div>
      <RadioGroup name="boolean-filter" value={value} onChange={v => setValue(Boolean(v.target.value))}>
        <FormControlLabel value={true} control={<Radio />} label="true" />
        <FormControlLabel value={false} control={<Radio />} label="false" />
      </RadioGroup>
    </div>
  );
}
