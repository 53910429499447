import TemperatureSlider from '../../../../reusables/TemperatureSlider';
import SettingWrapper from '../components/SettingWrapper';

type RiskPostureKey = 'attackScoreWeight' | 'passFailScoreThreshold';

const labels: Record<string, string> = {
  attackScoreWeight: 'Attack Score Weight',
  passFailScoreThreshold: 'Pass/Fail Score Threshold',
};

const defaultValue = { attackScoreWeight: 0.005, passFailScoreThreshold: 0.25 };

export default function RiskPosture() {
  return (
    <SettingWrapper title="Risk Posture" setting="riskPosture" defaultValue={defaultValue} json>
      {(riskPosture, onUpdate) => (
        <>
          {Object.keys(riskPosture).map(key => (
            <TemperatureSlider
              key={key}
              label={labels[key]}
              value={riskPosture[key as RiskPostureKey] * 100}
              onChange={(e, value) => onUpdate({ ...riskPosture, [key]: (value as number) / 100 })}
              showInput
            />
          ))}
        </>
      )}
    </SettingWrapper>
  );
}
