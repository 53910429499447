import { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

interface SliderLabelProps {
  label?: string;
  children: ReactNode;
}

export default function SliderControl({ label, children }: SliderLabelProps) {
  if (!label) {
    return <>{children}</>;
  }

  return (
    <Stack>
      <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
        {label}
      </Typography>
      {children}
    </Stack>
  );
}
