import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FrameworkWrapper from '../FrameworkWrapper';
import { PageTab } from '../interfaces';
import {
  FrameworkItemWithScores,
  ScoredFramework,
  ScoredFrameworkItem,
  TacticScore,
  ThresholdColors,
} from '../../../../services/interfaces';
import { ConfigValidationItem } from '../configValidation/interfaces';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    height: '100dvh',
    backgroundColor: theme.palette.darkBlue3.main,
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 999,
  },
}));

interface FrameworkWrapperProps {
  activeTab: PageTab;
  tabs: PageTab[];
  backgroundColor: (s?: TacticScore) => string;
  thresholdColors: ThresholdColors;
  assessorThreshold: number;
  compoundWeights: number;
  refreshScoring: () => void;
  onControlClick: (
    control: ScoredFrameworkItem | FrameworkItemWithScores | ConfigValidationItem,
    columnName?: string,
  ) => void;
  onTabClick: (tab: PageTab) => void;
  scoredFramework: ScoredFramework | null;
  scoredFrameworkDay1: ScoredFramework | null;
  scoredFrameworkDay2: ScoredFramework | null;
}

interface FullScreenAssessmentsModalProps {
  assessmentData: FrameworkWrapperProps;
}

export default function FullScreenAssessmentsModal({ assessmentData }: FullScreenAssessmentsModalProps) {
  const classes = useStyles();

  const {
    activeTab,
    tabs,
    onTabClick,
    backgroundColor,
    thresholdColors,
    assessorThreshold,
    refreshScoring,
    compoundWeights,
    onControlClick,
    scoredFramework,
    scoredFrameworkDay1,
    scoredFrameworkDay2,
  } = assessmentData;

  return (
    <Box className={classes.wrapper}>
      <FrameworkWrapper
        {...{
          refreshScoring,
          activeTab,
          tabs,
          backgroundColor,
          thresholdColors,
          assessorThreshold,
          compoundWeights,
          onControlClick,
          onTabClick,
          scoredFramework,
          scoredFrameworkDay1,
          scoredFrameworkDay2,
        }}
      />
    </Box>
  );
}
