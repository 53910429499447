import { UIEvent, useMemo, useRef } from 'react';
import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  FrameworkItemWithScores,
  ScoredFramework,
  ScoredFrameworkItem,
  TacticScore,
  ThresholdColors,
} from '../../../services/interfaces';
import CapabilityMaturity from './capabilityMaturity';
import DateHeader from './DateHeader';
import Framework from './Framework';
import FrameworkTabs from './FrameworkTabs';
import ImportAssessorScore from './ImportAssessorScores';
import { PageTab, ScoreType, TabView } from './interfaces';
import ConfigValidation from './configValidation';
import { ConfigValidationItem } from './configValidation/interfaces';
import { useFullScreenStore } from '../../../reusables/FullScreen';

interface FrameworkWrapperProps {
  activeTab: PageTab;
  tabs: PageTab[];
  backgroundColor: (s?: TacticScore) => string;
  refreshScoring: () => void;
  thresholdColors: ThresholdColors;
  assessorThreshold: number;
  compoundWeights: number;
  onControlClick: (
    control: ScoredFrameworkItem | FrameworkItemWithScores | ConfigValidationItem,
    columnName?: string,
  ) => void;
  onTabClick: (tab: PageTab) => void;
  scoredFramework: ScoredFramework | null;
  scoredFrameworkDay1: ScoredFramework | null;
  scoredFrameworkDay2: ScoredFramework | null;
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  tabPanel: (props: { isFullScreen: boolean }) => ({
    height: props.isFullScreen ? '100dvh' : 'calc(100vh - 480px)',
    background: '#192637',
    borderRadius: 6,
  }),
  compare: {
    display: 'flex',
    gap: 12,
    '& > *': {
      width: 'calc(50% - 13px)',
    },
  },
  charts: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'stretch',
  },
  bar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function FrameworkWrapper({
  activeTab,
  tabs,
  onTabClick,
  backgroundColor,
  refreshScoring,
  thresholdColors,
  assessorThreshold,
  compoundWeights,
  onControlClick: onClick,
  scoredFramework,
  scoredFrameworkDay1,
  scoredFrameworkDay2,
}: FrameworkWrapperProps) {
  const { isFullScreen } = useFullScreenStore();
  const classes = useStyles({ isFullScreen });

  const day1Ref = useRef<HTMLDivElement>(null);
  const day2Ref = useRef<HTMLDivElement>(null);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollLeft } = e.currentTarget;
    if (day1Ref.current) {
      day1Ref.current.scrollTop = scrollTop;
      day1Ref.current.scrollLeft = scrollLeft;
    }

    if (day2Ref.current) {
      day2Ref.current.scrollTop = scrollTop;
      day2Ref.current.scrollLeft = scrollLeft;
    }
  };

  const scoreType = useMemo(() => {
    if (activeTab === PageTab.RISK_POSTURE) {
      return ScoreType.SECMIT;
    }

    if (activeTab === PageTab.CONTROL_EFFICACY) {
      return ScoreType.CAPMIT;
    }

    if (activeTab === PageTab.THREAT_INTELLIGENCE) {
      return ScoreType.HEAT;
    }

    if (activeTab === PageTab.ASSESSOR_SCORE) {
      return ScoreType.ASSESSOR;
    }

    if (activeTab === PageTab.COMPOUND) {
      return ScoreType.COMPOUND;
    }

    if (activeTab === PageTab.CAPABILITY_MATURITY) {
      return ScoreType.CAPABILITY_MATURITY;
    }

    // TODO: Add ScoreType for Config. Validation page tab

    return ScoreType.NONE;
  }, [activeTab]);

  const view = useMemo(() => {
    if (activeTab === PageTab.CAPABILITY_MATURITY) {
      return TabView.CAPABILITY_MATURITY;
    }

    if (activeTab === PageTab.CONFIG_VALIDATION) {
      return TabView.CONFIG_VALIDATION;
    }

    if (scoredFrameworkDay1 && scoredFrameworkDay2) {
      return TabView.COMPARE;
    }

    return TabView.SINGLE;
  }, [activeTab, scoredFrameworkDay1, scoredFrameworkDay2]);

  const controls = activeTab === PageTab.ASSESSOR_SCORE ? <ImportAssessorScore onSave={refreshScoring} /> : <></>;

  return (
    <div className={classes.root}>
      <FrameworkTabs tabs={tabs} activeTab={activeTab} onTabClick={onTabClick} />
      <div className={classes.tabPanel} role="tabpanel">
        {view === TabView.SINGLE && (
          <Framework
            scoreType={scoreType}
            hideTitle
            onClick={onClick}
            scoredFramework={scoredFramework}
            activeTab={activeTab}
            backgroundColor={backgroundColor}
            thresholdColors={thresholdColors}
            assessorThreshold={assessorThreshold}
            compoundWeights={compoundWeights}
            controls={controls}
          />
        )}

        {view === TabView.CAPABILITY_MATURITY && <CapabilityMaturity onClick={onClick} onScroll={handleScroll} />}

        {view === TabView.CONFIG_VALIDATION && <ConfigValidation onClick={onClick} onScroll={handleScroll} />}

        {view === TabView.COMPARE && (
          <div className={classes.compare}>
            <div role="region" data-testid="scoring-comparison-day-1">
              <div style={{ marginBottom: 8 }}>
                <DateHeader date={scoredFrameworkDay1?.toDate ?? ''} />
              </div>
              <Framework
                scoreType={scoreType}
                hideTitle
                onClick={onClick}
                scoredFramework={scoredFrameworkDay1}
                onScroll={handleScroll}
                divRef={day1Ref}
                activeTab={activeTab}
                dateHeader
                backgroundColor={backgroundColor}
                thresholdColors={thresholdColors}
                assessorThreshold={assessorThreshold}
                compoundWeights={compoundWeights}
                controls={<></>}
              />
            </div>
            <Divider orientation="vertical" style={{ width: 2 }} />
            <div role="region" data-testid="scoring-comparison-day-2">
              <div style={{ marginBottom: 8 }}>
                <DateHeader date={scoredFrameworkDay2?.toDate ?? ''} />
              </div>
              <Framework
                scoreType={scoreType}
                hideTitle
                onClick={onClick}
                scoredFramework={scoredFrameworkDay2}
                onScroll={handleScroll}
                divRef={day2Ref}
                activeTab={activeTab}
                dateHeader
                backgroundColor={backgroundColor}
                thresholdColors={thresholdColors}
                assessorThreshold={assessorThreshold}
                compoundWeights={compoundWeights}
                controls={<></>}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
