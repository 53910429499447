import { InputAdornment } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from '../../../../../reusables/TextField';
import { CredentionalsPromptProps } from '../interfaces';

export default function PasswordPrompt({ ...props }: CredentionalsPromptProps) {
  const { passwordValue, onPasswordChange = () => {}, passwordError, passwordLabel = '', passwordPlaceholder } = props;

  return (
    <TextField
      type="password"
      value={passwordValue}
      startAdornment={
        <InputAdornment position="start">
          <FontAwesomeIcon size="lg" icon={['fal', 'lock']} />
        </InputAdornment>
      }
      label={passwordLabel}
      error={passwordError}
      onChange={onPasswordChange}
      placeholder={passwordPlaceholder}
      data-testid="password-prompt"
    />
  );
}
