import { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardActions, CardContent, CardHeader, IconButton } from '@mui/material';
import appStore from '../../../../../AppStore';

interface WrapperProps {
  title: string;
  children: ReactNode;
  clean: boolean;
  onSave: () => unknown;
  onReset: () => unknown;
}

export default function Wrapper({ onSave, onReset, title, children, clean }: WrapperProps) {
  const handleOnSave = async () => {
    try {
      await onSave();
      appStore.success(`Settings saved successfully`);
    } catch (err) {
      appStore.error(err);
    }
  };

  return (
    <Card sx={{ backgroundColor: 'rgba(255,255,255,0.07)' }}>
      <CardHeader sx={{ backgroundColor: '#0a0c10' }} title={title} />
      <CardContent>{children}</CardContent>
      <CardActions sx={{ justifyContent: 'end' }}>
        <IconButton color="warning" onClick={onReset} aria-label="Reset to defaults">
          <FontAwesomeIcon icon={['fal', 'undo']} />
        </IconButton>
        <IconButton disabled={clean} onClick={handleOnSave} aria-label="Save">
          <FontAwesomeIcon icon={['fal', 'save']} />
        </IconButton>
      </CardActions>
    </Card>
  );
}
