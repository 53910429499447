import { Stack, Typography } from '@mui/material';
import TemperatureSlider from '../../../../reusables/TemperatureSlider';
import SettingWrapper from '../components/SettingWrapper';

export default function FrameworkScoringWeights() {
  return (
    <SettingWrapper
      title="Framework Scoring Weights"
      setting="frameworkScoringWeights"
      defaultValue={{ prevented: 0.8, detected: 0.2, alerted: 0.4 }}
      json
    >
      {(weights, onUpdate) => (
        <Stack>
          <Typography variant="body2">
            The points given to a test result based on prevention, detection, and alerting. Maximum score per result is
            100 no matter the sum of the given weights.
          </Typography>
          {['prevented', 'detected', 'alerted'].map(key => (
            <TemperatureSlider
              label={key}
              key={`framework-scoring-weight-${key}`}
              value={weights[key as keyof typeof weights] * 100}
              onChange={(event, value) => onUpdate({ ...weights, [key]: (value as number) / 100 })}
              showInput
            />
          ))}
        </Stack>
      )}
    </SettingWrapper>
  );
}
