import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import appStore from '../../../AppStore';
import systemUsersService from '../../services/SystemUsersService';
import ticketingService from '../../services/TicketingService';
import APITicketingForm from './Ticketing/APITicketingForm';
import BasicTicketingForm from './Ticketing/BasicTicketingForm';
import Row from './components/Row';
import { useAbort } from '../../../effects';
import TextField from '../../reusables/TextField';
import { settingsService } from '../../services';
import { OnSaveCallback, TicketingSetting } from './interfaces';
import { toURL } from './utilities';

const TICKETING = 'ticketing';

const useStyles = makeStyles(() => (
  {
    description: {
      marginTop: 16,
      marginBottom: 39,
    },
    button: {
      fontSize: 14,
      color: '#fff',
      borderRadius: 2,
      outline: 'none',
    },
    testBtn: {
      border: 'solid 0.5px #fff',
      backgroundColor: 'rgba(40, 43, 54, 0.62)',
    },
    submitBtn: {
      backgroundColor: '#21bee3',
    },
  }
));

const emptyConfig = {
  integrationType: 'none',
  systemUser: '',
  password: '',
  host: '',
  protocol: 'https',
  type: TICKETING,
};

export default function Ticketing() {
  const classes = useStyles();
  const [ticketing, setTicketing] = useState<TicketingSetting>({ ...emptyConfig });
  const [testDisabled, setTestDisabled] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [onSaveCallbacks, setOnSaveCallbacks] = useState<OnSaveCallback[]>([]);

  useAbort(
    () => systemUsersService.list(TICKETING),
    ([user]) => {
      if (user) {
        setTicketing(user as TicketingSetting);
      }
    },
    [],
  );

  const save = useCallback(async () => {
    appStore.beginLoading();

    try {
      await systemUsersService.remove(TICKETING);   

      if (ticketing.integrationType !== 'none') {
        const updated = {
          ...ticketing,
          __typename: undefined, // need to remove the field from existing GQL response
        };

        await systemUsersService.createOrUpdate(updated);

        await settingsService.save({ name: 'ticketing_integration_url', value: toURL(ticketing) });
      
        onSaveCallbacks.forEach(cb => cb());
      }

      appStore.success('Configuration Saved');
    } catch (err) {
      appStore.error(err);
    }
    
    setSaveDisabled(true);
    appStore.endLoading();
  }, [ticketing, onSaveCallbacks]);

  const testConnection = useCallback(async () => {
    appStore.beginLoading();
    try {
      const updated = {
        ...ticketing,
        __typename: undefined, // need to remove the field from existing GQL response
      };

      await ticketingService.testConnection(updated);

      setSaveDisabled(false);
      appStore.success('Connection Established Successfully');
    } catch (err) {
      setSaveDisabled(true);
      appStore.error(err);
    }
    appStore.endLoading();
  }, [ticketing]);

  const handleChange = useCallback(({ target: { value } }: { target: { value: string }}) => {
    setTicketing(prev => ({ ...prev, integrationType: value }));
    setSaveDisabled(value !== 'none');
  }, []);

  const handleUpdate = useCallback(
    (update: Partial<TicketingSetting>) => setTicketing(prev => ({ ...prev, ...update })),
    [],
  );

  const registerSaveCallback = useCallback((cb: OnSaveCallback) => {
    setOnSaveCallbacks([cb]);
  },[]);

  const handleEnableTestButton = useCallback((b: boolean) => setTestDisabled(!b), []);

  return (
    <div>
      <Typography variant="h3">Ticketing System Integration</Typography>
      <Typography variant="body1" className={classes.description}>
        Configure a ticketing system integration that can enable Live Fire process scoring.
      </Typography>
      <Row>
        <TextField
          label="Integration Type"
          value={ticketing.integrationType}
          type="select"
          onChange={handleChange}
        >
          <MenuItem value="none">
            None
          </MenuItem>
          <MenuItem value="archer">
            Archer IRM
          </MenuItem>
          <MenuItem value="serviceNow">
            ServiceNow
          </MenuItem>
          <MenuItem value="theHiveProject">
            TheHive Project
          </MenuItem>
        </TextField>
      </Row>
      {
        ['theHiveProject', 'serviceNow'].includes(ticketing.integrationType)
          && (
            <APITicketingForm
              data={ticketing}
              update={handleUpdate}
              enableUsername={ticketing.integrationType === 'serviceNow'}
              setTestEnabled={handleEnableTestButton}
            />
          )
      }
      {
        ticketing.integrationType === 'archer'
        && (          
          <BasicTicketingForm
            data={ticketing}
            update={handleUpdate}
            setTestEnabled={handleEnableTestButton}
            submitEnabled={!saveDisabled}
            registerSaveCallback={registerSaveCallback}
          />
        )
      }

      <Row>
        <Button
          className={clsx(classes.button, classes.testBtn)}
          onClick={testConnection}
          disabled={testDisabled}
          variant="contained"
          endIcon={<FontAwesomeIcon size="sm" icon={['fal', 'arrow-right']} />}
        >
          Test Connection
        </Button>
        <Button
          className={clsx(classes.button, classes.submitBtn)}
          onClick={save}
          disabled={saveDisabled}
          variant="contained"
          endIcon={<FontAwesomeIcon size="sm" icon={['fal', 'arrow-right']} />}
        >
          Submit

        </Button>
      </Row>
    </div>
  );
}
