import { useEffect } from 'react';
import Row from '../components/Row';
import { TicketingSetting } from '../interfaces';
import URLField from '../../../reusables/URLField';
import CredentionalsForm from '../components/CredentionalsForm';

export default function APITicketingForm({ data, update, setTestEnabled, enableUsername }: APITicketingFormProps) {
  const { host, protocol, port, password, systemUser } = data;

  // Allow Test Connection when host, port and API Key are supplied
  useEffect(() => {
    setTestEnabled(!!(host && protocol && port && password && (systemUser || !enableUsername)));
  }, [host, protocol, port, password, systemUser, enableUsername, setTestEnabled]);

  return (
    <>
      <Row>
        <URLField host={host} protocol={protocol} port={port} onChange={update} />
      </Row>
      <Row column>
        {enableUsername ? (
          <CredentionalsForm
            variant="basic"
            usernameValue={systemUser}
            usernameLabel="Username"
            usernameError={!systemUser && 'Username is required'}
            onUsernameChange={e => update({ systemUser: e.target.value })}
            passwordValue={password}
            passwordLabel="Password"
            passwordError={!password && `Password is required`}
            onPasswordChange={e => update({ password: e.target.value })}
          />
        ) : (
          <CredentionalsForm
            variant="token"
            tokenValue={password}
            tokenLabel="API Key"
            passwordError={!password && `API Key is required`}
            onTokenChange={e => update({ password: e.target.value })}
          />
        )}
      </Row>
    </>
  );
}

interface APITicketingFormProps {
  data: TicketingSetting;
  update: (v: Partial<TicketingSetting>) => void;
  setTestEnabled: (v: boolean) => void;
  enableUsername?: boolean;
}
