import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ReactNode } from 'react';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    gap: 25,
    marginBottom: 33,
    alignItems: 'flex-start',
  },
  content: {
    padding: '1.75rem 1.25rem 1.25rem',
    backgroundColor: '#192637',
    marginBottom: 0,
  },
  column: {
    flexDirection: 'column',
    gap: 0,
  },
}));

interface RowProps {
  children: ReactNode;
  content?: boolean;
  column?: boolean;
}

export default function Row({ children, content = false, column = false }: RowProps) {
  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.wrapper]: true,
        [classes.content]: !!content,
        [classes.column]: !!column,
      })}
    >
      {children}
    </div>
  );
}
