import { useMemo } from 'react';
import { Slider, SliderProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useThresholdColors } from '../useSetting';
import SliderControl from './SliderControl';
import SliderInput from './SliderInput';
import TemperatureComponent from './TemperatureComponent';

interface TemperatureSliderProps extends SliderProps {
  reversed?: boolean;
  defaultValue?: number[];
  showInput?: boolean;
  label?: string;
}

const useStyles = makeStyles({
  root: {
    color: ({ color }: { color: string }) => color,
    height: 4,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  thumb: {
    backgroundColor: '#fff',
    border: '2px solid #fff',
    marginTop: -3,
  },
  valueLabel: {
    left: 'calc(-50% - 8px)',
    top: 20,
    '& *': {
      background: 'transparent',
      color: '#fff',
    },
  },
  track: {
    height: 4,
  },
  rail: {
    height: 4,
    opacity: 1,
  },
});

export default function TemperatureSlider({
  value: original,
  reversed,
  defaultValue = [0, 0],
  showInput,
  ...props
}: TemperatureSliderProps) {
  const thresholdColors = useThresholdColors();
  const value = useMemo(
    () => (typeof original === 'string' ? JSON.parse(original) : original ?? defaultValue),
    [original],
  );

  const { colors, rootBg } = useMemo(() => {
    const colors = [
      removeTransparency(thresholdColors?.red),
      removeTransparency(thresholdColors?.orange),
      removeTransparency(thresholdColors?.yellow),
      removeTransparency(thresholdColors?.green),
    ];

    // get rid of orange
    if (value.length === 2) {
      colors.splice(1, 1);
    }

    // flip the colors around
    if (reversed) {
      colors.reverse();
    }

    // take off the rail color
    const rootBg = colors.pop();

    return { colors, rootBg, value };
  }, [reversed, thresholdColors, value]);

  const classes = useStyles({ color: rootBg as string });

  return (
    <SliderControl label={props.label}>
      <div className={classes.container}>
        <Slider
          track={false}
          classes={classes}
          component={TemperatureComponent}
          {...props}
          colors={colors}
          value={value}
        />
        {showInput && (
          <SliderInput
            inputProps={{ max: props.max, min: props.min, step: props.step }}
            value={value}
            onChange={props.onChange}
          />
        )}
      </div>
    </SliderControl>
  );
}

function removeTransparency(color: string) {
  if (!color.match(/^rgba/)) {
    return color;
  }
  return color.replace(/^rgba/, 'rgb').replace(/,[^,]+\)$/, ')');
}
