import TemperatureSlider from '../../../../reusables/TemperatureSlider';
import SettingWrapper from '../components/SettingWrapper';

export default function PIIExposureWeight() {
  return (
    <SettingWrapper title="PII Exposure Weight" setting="piiWeight" defaultValue={0.25}>
      {(value, onUpdate) => (
        <TemperatureSlider
          label="PII value (supplied via company profile)"
          value={value * 100}
          onChange={(event, value) => onUpdate((value as number) / 100)}
          showInput
        />
      )}
    </SettingWrapper>
  );
}
